import React from "react"
import { DatePicker } from "antd"
import "antd/dist/antd.min.css"
import { RangePickerProps } from "antd/lib/date-picker"

const { RangePicker } = DatePicker

type Props = RangePickerProps

const DateRangePicker: React.FC<Props> = ({ allowClear = true, placeholder, format, value, onChange, ...rest }) => {
	return (
		<RangePicker
			placeholder={placeholder}
			allowClear={allowClear}
			value={value}
			format={format}
			onChange={onChange}
			{...rest}
		/>
	)
}

export default DateRangePicker
