import { IconName } from "components/types"
import { IconType } from "react-icons"
import { ImSpinner3, ImPlus } from "react-icons/im"
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi"
import {
	MdOutlineVisibilityOff,
	MdOutlineVisibility,
	MdDone,
	MdClose,
	MdOutlineClose,
	MdMode,
	MdPrint,
	MdFileDownload,
	MdOutlineCleaningServices,
	MdAdminPanelSettings,
	MdCalendarToday,
	MdOutlineReport,
	MdCheck,
	MdFilterAlt,
	MdOutlineHourglassDisabled,
} from "react-icons/md"
import { FaArrowUp, FaArrowDown, FaExclamation } from "react-icons/fa"
import { VscHistory } from "react-icons/vsc"
import { BsRecordCircleFill, BsArrowRight, BsArrowLeft, BsCurrencyPound, BsQuestionLg } from "react-icons/bs"
import { BiSearch } from "react-icons/bi"
import { AiOutlineInfoCircle, AiOutlineSecurityScan, AiOutlineCamera } from "react-icons/ai"
import { IoTrashOutline } from "react-icons/io5"

const iconMap: { [key in IconName]: IconType } = {
	upArrowTable: FaArrowUp,
	downArrowTable: FaArrowDown,
	spinner: ImSpinner3,
	add: ImPlus,
	history: VscHistory,
	circleFilled: BsRecordCircleFill,
	dropdownArrow: HiChevronDown,
	dropRightArrow: HiChevronRight,
	dropLeftArrow: HiChevronLeft,
	rightArrow: BsArrowRight,
	leftArrow: BsArrowLeft,
	visibilityOff: MdOutlineVisibilityOff,
	visibilityOn: MdOutlineVisibility,
	approve: MdDone,
	deny: MdClose,
	cross: MdOutlineClose,
	delete: IoTrashOutline,
	edit: MdMode,
	print: MdPrint,
	pound: BsCurrencyPound,
	download: MdFileDownload,
	search: BiSearch,
	info: AiOutlineInfoCircle,
	filter: MdFilterAlt,
	security: AiOutlineSecurityScan,
	cleaning: MdOutlineCleaningServices,
	warning: FaExclamation,
	camera: AiOutlineCamera,
	schedules: MdCalendarToday,
	admin: MdAdminPanelSettings,
	reporting: MdOutlineReport,
	inactive: FaExclamation,
	pending: MdOutlineHourglassDisabled,
	rejected: MdClose,
	success: MdCheck,
	unallocated: BsQuestionLg,
}

export default iconMap
