import useAppSelector from "hooks/useAppSelector"
import { selectSelectedLocationTrends } from "store/Location/locationDetails.slice"
import React, { useMemo } from "react"
import ReactApexChart from "react-apexcharts"
import { theme } from "config"
import { ApexOptions } from "apexcharts"
import moment from "moment"

interface ApexChartProps {}
type keyTypes =
	| "criticalShifts"
	| "lateShifts"
	| "mobileBookOns"
	| "unAllocatedShifts"
	| "allocatedShifts"
	| "unCoveredShifts"
const keysToPlot: { label: string; key: keyTypes }[] = [
	{ label: "Critical Shifts", key: "criticalShifts" },
	{ label: "Late Shifts", key: "lateShifts" },
	{ label: "Covered", key: "allocatedShifts" },
	{ label: "Unallocated", key: "unAllocatedShifts" },
	{ label: "Uncovered", key: "unCoveredShifts" },
]
const ApexChart: React.FC<ApexChartProps> = () => {
	const trendsData = useAppSelector(selectSelectedLocationTrends())

	const xCategories = useMemo(() => {
		return trendsData?.map(trend => trend._id)
	}, [trendsData])
	const stepSize = useMemo(() => {
		const totalLabels = xCategories?.length
		const maxLabels = 10 // Maximum desired labels
		let step = 0
		if (totalLabels) {
			if (totalLabels - maxLabels === 0) {
				step = maxLabels
			} else if (totalLabels - maxLabels >= 30) {
				step = Math.ceil(totalLabels / maxLabels)
			} else {
				step = 0
			}
		}
		return step
	}, [xCategories])

	const options: ApexOptions = {
		chart: {
			height: 350,
			type: "line",
			toolbar: {
				show: false,
			},
		},
		stroke: {
			curve: "smooth",
		},
		grid: {
			show: false,
		},
		title: {
			text: "Trends(%)",
			align: "center",
			style: {
				color: "black",
				fontFamily: "Poppins",
				fontSize: "16px",
				fontWeight: 600,
			},
		},
		xaxis: {
			categories: xCategories,
			labels: {
				formatter: function (value) {
					const parsedDate = moment(value, "YYYY-MM-DD")
					return parsedDate.format("DD-MMM")
				},
			},
			tickAmount: stepSize,
			title: {
				text: "Timeline",
				style: {
					color: "black",
					fontFamily: "Poppins",
					fontSize: "16px",
					fontWeight: 600,
				},
			},
		},
		colors: [
			theme.colors.danger,
			theme.colors.warning,
			theme.colors.success,
			theme.colors["danger-light"],
			theme.colors.tangerine,
		],
		yaxis: {
			max: 100,
			tickAmount: 4,

			title: {
				text: "Percentage %",
				style: {
					color: "black",
					fontFamily: "Poppins",
					fontSize: "16px",
					fontWeight: 600,
				},
			},
		},
		legend: {
			markers: {
				fillColors: [
					theme.colors.danger,
					theme.colors.warning,
					theme.colors.success,
					theme.colors["danger-light"],
					theme.colors.tangerine,
				],
			},
		},
		tooltip: {
			y: {
				formatter: function (value) {
					return `${value}%`
				},
			},
		},
	}
	const series = useMemo(() => {
		return trendsData
			? keysToPlot.map(({ key, label }) => ({
					name: label,
					data: trendsData.map(trend => {
						if (key === "unAllocatedShifts" || key === "unCoveredShifts" || key === "allocatedShifts") {
							return +Number(
								(trend[key] * 100) /
									(trend.allocatedShifts + trend.unAllocatedShifts + trend.unCoveredShifts),
							).toFixed(2)
						}
						return trend[key]
					}),
			  }))
			: []
	}, [trendsData])

	return (
		<div className="h-full">
			{trendsData?.length ? (
				<div id="charts">
					<ReactApexChart options={options} series={series} type="line" height={300} />
				</div>
			) : (
				<div className="flex h-full items-center justify-center py-5">
					<span className="text-xl font-semibold">No Data</span>
				</div>
			)}
		</div>
	)
}

export default ApexChart
