import { sortByProperty, updateState } from "config/utils"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetPayGroupsInterface, CreatePayGroupsInterface } from "@type/payGroup.types"
import { payGroupService } from "services"
import { AppThunk, RootState } from "store"

export interface payGroupStateInterface {
	list: GetPayGroupsInterface[]
	loading: boolean
	saving: boolean
}

const initialState: payGroupStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const payGroupSlice = createSlice({
	name: "payGroup",
	initialState,
	reducers: {
		fetchingPayGroup: payGroup => {
			payGroup.loading = true
		},
		payGroupFetched: (payGroup, action: PayloadAction<GetPayGroupsInterface[]>) => {
			payGroup.loading = false
			payGroup.list = action.payload
		},
		payGroupFetchingFailed: payGroup => {
			payGroup.loading = false
		},
		savingPayGroup: payGroupData => {
			payGroupData.saving = true
		},
		payGroupSaved: (payGroup, action: PayloadAction<GetPayGroupsInterface>) => {
			payGroup.saving = false
			const [UpdatedValue, NewAdded] = updateState(payGroup.list, action.payload, "_id")

			if (NewAdded) {
				payGroup.list = UpdatedValue
			}
		},
		payGroupSavingFailed: payGroup => {
			payGroup.saving = false
		},
	},
})

//REDUCER
export default payGroupSlice.reducer

//ACTIONS
const {
	fetchingPayGroup,
	payGroupFetched,
	payGroupFetchingFailed,
	savingPayGroup,
	payGroupSaved,
	payGroupSavingFailed,
} = payGroupSlice.actions

const getPayGroup =
	(cb?: (id: string) => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingPayGroup())
			const { data: payGroupResponse } = await payGroupService.getAllPayGroups()
			payGroupResponse.sort(sortByProperty("name"))
			if (cb) cb(payGroupResponse[0]?._id)
			dispatch(payGroupFetched(payGroupResponse))
		} catch (error) {
			dispatch(payGroupFetchingFailed())
		}
	}

const savePayGroup =
	(payGroupData: Partial<GetPayGroupsInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data = null
		try {
			dispatch(savingPayGroup())
			if (payGroupData?._id) data = await payGroupService.updatePayGroup(payGroupData._id, payGroupData)
			else data = await payGroupService.createPayGroups(payGroupData as CreatePayGroupsInterface)
			const { data: payGroupResponse } = data
			cb && cb(payGroupResponse._id)
			dispatch(payGroupSaved(payGroupResponse))
		} catch (error) {
			dispatch(payGroupSavingFailed())
		}
	}

export { getPayGroup, savePayGroup }

//SELECTORS
const selectPayGroupState = (state: RootState) => state.payGroups
const isPayGroupLoading = () => (state: RootState) => selectPayGroupState(state).loading
const selectPayGroupData = () => (state: RootState) => selectPayGroupState(state).list || []
const isPayGroupSaving = () => (state: RootState) => selectPayGroupState(state).saving
const selectPayGroupById = (id: string) => (state: RootState) =>
	selectPayGroupState(state)?.list?.find(item => item._id === id)
export { selectPayGroupData, selectPayGroupState, isPayGroupSaving, isPayGroupLoading, selectPayGroupById }
