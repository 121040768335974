import { Empty } from "antd-v5"
import React from "react"

interface Props {
	description?: string
	children?: React.ReactNode
}

const AntdEmpty: React.FC<Props> = ({ description = "", children }) => {
	return (
		<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description || undefined}>
			{children || <></>}
		</Empty>
	)
}

export default AntdEmpty
