import axios from "axios"
import {
	CreateLocationDetailsInterface,
	GetAllLocationDetailsInterface,
	GetLocationDetailsInterface,
	GetLocationStats,
	LocationStats,
} from "@type/locations.types"

const ROOT_PATH = "locations/details"
const ROOT_PATH_PARENT = "locations"

export default class LocationsService {
	static createLocation = (location: CreateLocationDetailsInterface) =>
		axios.post<GetLocationDetailsInterface>(ROOT_PATH, location)

	static getAllLocations = () => axios.get<Array<GetLocationDetailsInterface>>(`${ROOT_PATH}`)
	static getAllLocationsDetail = () => axios.get<Array<GetAllLocationDetailsInterface>>("locations")

	static getLocationByBranchId = (id: string) =>
		axios.get<Array<GetLocationDetailsInterface>>(`${ROOT_PATH}/?branch=${id}`)

	static getLocationDetailsById = (id: string) => axios.get<GetLocationDetailsInterface>(`${ROOT_PATH}/${id}`)

	static getLocationBySearch = (location: { name?: string; sin?: string }) =>
		axios.get<GetLocationDetailsInterface>(`${ROOT_PATH_PARENT}/enhanced-search`, { params: location })

	static updateLocationDetails = (locationId: string, location: Partial<GetLocationDetailsInterface>) =>
		axios.put<GetLocationDetailsInterface>(`${ROOT_PATH}/${locationId}`, location)

	static getLocationStats = (locations: GetLocationStats) =>
		axios.post<LocationStats[]>(`schedules/shift-stats`, locations)
}
