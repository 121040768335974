import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppThunk, RootState } from "store"
import { AxiosResponse } from "axios"
import { ConfirmationDataInterface } from "@type/global.types"
interface ConfirmationResponseInterface {
	request: {
		method: string
		url: string
		body: { [key: string]: any }
	}
	confirmation: ConfirmationDataInterface
	targetReducer?: string
}
export interface GlobalStateInterface {
	selectedBranch: string
	selectedLocationBranch: string
	selectedEmployeeBranch: string
	selectedCustomer?: string
	selectedSupplier?: string
	selectedEmployee: string
	selectedLocation: string
	confirmationResponse?: ConfirmationResponseInterface
}

const initialState: GlobalStateInterface = {
	selectedBranch: "",
	selectedLocationBranch: "",
	selectedEmployeeBranch: "",
	selectedCustomer: "",
	selectedSupplier: "",
	selectedEmployee: "",
	selectedLocation: "",
}

const globalStateSlice = createSlice({
	name: "featureFlags",
	initialState,
	reducers: {
		setSelectedBranch: (state, action: PayloadAction<string>) => {
			state.selectedBranch = action.payload
			state.selectedLocationBranch = action.payload
			state.selectedEmployeeBranch = action.payload
			state.selectedSupplier = ""
		},
		setSelectedLocationBranch: (state, action: PayloadAction<string>) => {
			state.selectedLocationBranch = action.payload
		},
		setSelectedEmployeeBranch: (state, action: PayloadAction<string>) => {
			state.selectedEmployeeBranch = action.payload
			state.selectedSupplier = ""
		},
		setSelectedCustomer: (state, action: PayloadAction<string>) => {
			state.selectedCustomer = action.payload
		},
		clearSelectedCustomer: state => {
			state.selectedCustomer = ""
		},
		clearSelectedBranch: state => {
			state.selectedBranch = ""
			state.selectedLocationBranch = ""
			state.selectedEmployeeBranch = ""
		},
		setSelectedSupplier: (state, action: PayloadAction<string>) => {
			state.selectedSupplier = action.payload
			state.selectedBranch = ""
			state.selectedLocationBranch = ""
			state.selectedEmployeeBranch = ""
		},
		clearSelectedSupplier: state => {
			state.selectedSupplier = ""
		},
		setSelectedEmployee: (state, action: PayloadAction<string>) => {
			state.selectedEmployee = action.payload
		},
		clearSelectedEmployee: state => {
			state.selectedEmployee = ""
		},
		setSelectedLocation: (state, action: PayloadAction<string>) => {
			state.selectedLocation = action.payload
		},
		clearSelectedLocation: state => {
			state.selectedLocation = ""
		},
		setConfirmationModalData: (state, action: PayloadAction<ConfirmationResponseInterface>) => {
			state.confirmationResponse = action.payload
		},
		clearConfirmationModalData: state => {
			delete state.confirmationResponse
		},
	},
})

// REDUCER
export default globalStateSlice.reducer

// ACTIONS
export const {
	setSelectedBranch,
	clearSelectedBranch,
	setSelectedCustomer,
	clearSelectedCustomer,
	setSelectedSupplier,
	clearSelectedSupplier,
	setSelectedEmployee,
	clearSelectedEmployee,
	setSelectedLocation,
	clearSelectedLocation,
	setSelectedLocationBranch,
	setSelectedEmployeeBranch,
	setConfirmationModalData,
	clearConfirmationModalData,
} = globalStateSlice.actions

export const handleConfirmationResponse =
	(response: AxiosResponse, targetReducer?: string): AppThunk =>
	async dispatch => {
		const { data, config } = response
		const { data: reqBody = {}, method = "", url = "", baseURL = "" } = config
		const { confirmation = {} } = data

		let body: any = {}
		if (["post", "put"].includes(method)) {
			if (typeof reqBody === "string") body = JSON.parse(reqBody)
		}

		dispatch(
			setConfirmationModalData({
				request: { body, method, url: baseURL + url },
				confirmation,
				...(targetReducer && { targetReducer }),
			}),
		)
	}

// SELECTORS
const selectSelectedGlobalState = (state: RootState) => state.globalState
const selectSelectedBranch = (state: RootState) => selectSelectedGlobalState(state).selectedBranch
const selectSelectedLocationBranch = (state: RootState) => selectSelectedGlobalState(state).selectedLocationBranch
const selectSelectedEmployeeBranch = (state: RootState) => selectSelectedGlobalState(state).selectedEmployeeBranch
const selectSelectedCustomer = (state: RootState) => selectSelectedGlobalState(state).selectedCustomer
const selectSelectedSupplier = (state: RootState) => selectSelectedGlobalState(state).selectedSupplier
const selectSelectedEmployee = (state: RootState) => selectSelectedGlobalState(state).selectedEmployee
const selectSelectedLocation = (state: RootState) => selectSelectedGlobalState(state).selectedLocation

const selectSelectedLocationDetails = createSelector(
	(state: RootState) => ({
		locationList: state.location.details.list || [],
		selectedLocationId: state.globalState.selectedLocation,
	}),
	({ locationList, selectedLocationId }) => locationList.find(list => list._id === selectedLocationId),
)
const selectConfirmationResponseData = (state: RootState) => selectSelectedGlobalState(state).confirmationResponse
export {
	selectSelectedLocationBranch,
	selectSelectedEmployeeBranch,
	selectSelectedBranch,
	selectSelectedCustomer,
	selectSelectedLocation,
	selectSelectedSupplier,
	selectSelectedEmployee,
	selectSelectedLocationDetails,
	selectConfirmationResponseData,
}
