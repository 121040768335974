import { useEffect, useRef, useState } from "react"
import { AntdButton, AntdSelect1, LocationSidebar } from "components"
import { selectSelectedLocation, setSelectedLocation } from "store/globalState.slice"
import { useAppDispatch, useAppSelector, useHeightResize } from "hooks"
import { CaretDownOutlined } from "@ant-design/icons"
import {
	getLocationContractsByLocationId,
	isLocationContractsLoading,
	selectLocationContractsDataDropDown,
} from "store/Location/locationContracts.slice"
import RotaPlannerModal from "./RotaPlannerModal"
import { clearLocationPosts, getLocationPostsByContractId } from "store/Location/locationPosts.slice"
import {
	clearRotaPlannerState,
	fetchRotaPlan,
	isRotaPlannerLoading,
	selectRotaPlannerData,
	updateRotaPlanTimes,
} from "store/RotaPlanner/rotaPlanner.slice"
import RotaPlannerTable from "./RotaPlannerTable"
import { UpdateRotaPlan } from "@type/rotaPlanner.types"
import DuplicateContractModal from "pages/locations/Contracts/DuplicateContractModal"

const RotaPlanner = () => {
	useHeightResize({ element: "planner-container" })
	const updateRowRef = useRef<UpdateRotaPlan[]>([])
	const dispatch = useAppDispatch()
	const selected = useAppSelector(selectSelectedLocation)

	//Contract Selector
	const contractsList = useAppSelector(selectLocationContractsDataDropDown(selected))
	const isContractsLoading = useAppSelector(isLocationContractsLoading())

	//Rota Plan Selector
	const rotaPlan = useAppSelector(selectRotaPlannerData)
	const isRotaPlanLoading = useAppSelector(isRotaPlannerLoading())

	// Modal State
	const [showRotaPlannerModal, setShowRotaPlannerModal] = useState(false)
	const [selectedContract, setSelectedContract] = useState<{ label: string; value: string }>(contractsList[0])
	const [showDuplicateContractModal, setShowDuplicateContractModal] = useState<boolean>(false)

	useEffect(() => {
		if (selected) {
			dispatch(getLocationContractsByLocationId(selected))
			dispatch(clearRotaPlannerState())
		}
		setSelectedContract(contractsList[0])
	}, [selected])

	useEffect(() => {
		dispatch(fetchRotaPlan(selectedContract.value))
	}, [selectedContract])

	const handleSubmit = () => {
		dispatch(updateRotaPlanTimes(selectedContract.value, updateRowRef.current, () => (updateRowRef.current = [])))
	}

	return (
		<div id="planner-container" className="h-full">
			<LocationSidebar
				selected={selected}
				handleSelected={locationId => dispatch(setSelectedLocation(locationId))}
				needEmployeeByBranch={false}
			/>
			<div className="flex w-1/2 space-x-4 py-3">
				<AntdSelect1
					allowClear
					disabled={!contractsList.length || !selected}
					onChange={event => {
						const selectedOptionsLabel =
							contractsList.find(({ value }) => value === event)?.label || "Select Contract"
						setSelectedContract({ label: selectedOptionsLabel, value: event })
						event ? dispatch(getLocationPostsByContractId(event)) : dispatch(clearLocationPosts())
					}}
					value={selectedContract.value}
					loading={isContractsLoading}
					placeholder="Select Contract"
					options={contractsList || []}
					suffixIcon={
						<>
							<span style={{ color: "rgb(156 163 175)" }}>Contract</span>
							<CaretDownOutlined style={{ color: "rgb(156 163 175)" }} />
						</>
					}
				/>
				{!rotaPlan?._id && (
					<AntdButton onClick={() => setShowRotaPlannerModal(prev => !prev)}>Create Plan</AntdButton>
				)}
				<AntdButton onClick={() => setShowDuplicateContractModal(prev => !prev)}>Duplicate Contract</AntdButton>
				{selectedContract.value && (
					<AntdButton onClick={handleSubmit} loading={isRotaPlanLoading} disabled={isRotaPlanLoading}>
						Save
					</AntdButton>
				)}
			</div>
			<RotaPlannerTable ref={updateRowRef} />
			{showRotaPlannerModal && (
				<RotaPlannerModal
					open={showRotaPlannerModal}
					modalHandler={setShowRotaPlannerModal}
					contractId={selectedContract.value}
				/>
			)}
			{showDuplicateContractModal && (
				<DuplicateContractModal
					open={showDuplicateContractModal}
					modalHandler={setShowDuplicateContractModal}
				/>
			)}
		</div>
	)
}

export default RotaPlanner
