import React, { useContext, useMemo } from "react"
import { useAppSelector } from "hooks"
import { AntdButton, Icon, Table2, TableSkeleton } from "components"
import DocumentContext, { DocumentContextProps } from "./documentContext"
import { selectLocationDocumentData, selectLoadingLocationDocument } from "store/Location/Documents/documents.slice"
import AuditTrail from "components/AuditTrail"
import { Entities } from "types/auditTrail.types"
import { addPathToDocument, caseConverters } from "config/utils"
import { momentZ } from "config/moment"
import { DATE_FORMATS } from "config/constants"
import GenericService from "services/generic/generic.service"

const Table: React.FC = () => {
	const locationDocument = useAppSelector(selectLocationDocumentData())
	const isLoading = useAppSelector(selectLoadingLocationDocument())

	const { handleRow } = useContext(DocumentContext) as DocumentContextProps

	const documentValue = useMemo(() => {
		if (locationDocument) {
			return locationDocument?.map((d, i) => {
				return {
					...d,
					serialNumber: i + 1,
				}
			})
		}

		return []
	}, [locationDocument])

	return (
		<div>
			{isLoading ? (
				<TableSkeleton />
			) : (
				<Table2
					headerClassName="text-center"
					columns={[
						{ label: "Sr. No", key: "serialNumber" },
						{ label: "Title", key: "title" },
						{ label: "Description", key: "description" },
						{
							label: "Type",
							key: "type",
							render: ({ row, key }) => <div> {caseConverters.titleCase(row[key])} </div>,
						},
						{
							label: "Start Date",
							key: "startDate",
							render: ({ row, key }) => {
								return row[key] ? (
									<div> {momentZ(row[key]).format(DATE_FORMATS.DD_MM_YYYY_HH_MM)}</div>
								) : (
									<></>
								)
							},
						},
						{
							label: "End Date",
							key: "endDate",
							render: ({ row, key }) => {
								return row[key] ? (
									<div> {momentZ(row[key]).format(DATE_FORMATS.DD_MM_YYYY_HH_MM)}</div>
								) : (
									<></>
								)
							},
						},
						{
							label: "Document",
							key: "link",
							render: ({ row, key }) => (
								<div className="flex justify-center ">
									<div
										className="flex w-fit cursor-pointer space-x-2 rounded-md border-0.25 border-black p-2"
										onClick={async () => {
											if (row[key]) {
												const downloadURL = await addPathToDocument(row[key])
												try {
													const response = await GenericService.getBlobResponse(downloadURL)
													const contentType = response.headers["content-type"]

													// Assuming the response.data is a Blob
													const blobData = new Blob([response.data], { type: contentType })

													const url = URL.createObjectURL(blobData)

													const tempLink = document.createElement("a")
													// tempLink.href = objectURL
													// tempLink.setAttribute("download", row["title"])
													tempLink.href = url
													tempLink.setAttribute("download", row["title"]) // Set the desired filename
													tempLink.style.display = "none" // Hide the link

													document.body.appendChild(tempLink)
													tempLink.click()
													document.body.removeChild(tempLink)

													URL.revokeObjectURL(url)

													// const blobData = await GenericService.getBlobResponse(downloadURL)
													// const objectURL = URL.createObjectURL(blobData)
													// const tempLink = document.createElement("a")
													// tempLink.href = objectURL
													// tempLink.setAttribute("download", "true.pdf")
													// tempLink.style.display = "none"
													// document.body.appendChild(tempLink) // Append the link to the document
													// tempLink.click()
													// document.body.removeChild(tempLink)
													// URL.revokeObjectURL(objectURL)
												} catch (error) {
													console.error("Error occurred while downloading the file:", error)
												}
											}
										}}
									>
										<Icon name="download" color="dark" size={22} />
										<span className="text-md">Download</span>
									</div>
								</div>
							),
						},
						{
							label: "History",
							key: "_id",
							render: ({ row }: any) => (
								<div className="flex justify-center">
									<AuditTrail id={row["_id"]} name={Entities.LOCATION_DOCUMENTS} showLabel={false} />
								</div>
							),
						},
						{
							label: "Action",
							key: "action",
							render: ({ row }) => {
								return (
									<div className="flex justify-center">
										<AntdButton
											className="my-1.25"
											onClick={e => {
												e.preventDefault()
												handleRow(row)
											}}
										>
											Update
										</AntdButton>
									</div>
								)
							},
						},
					]}
					rows={documentValue}
				/>
			)}
		</div>
	)
}

export default Table
