import { DATE_FORMATS } from "config/constants"
import { momentZ } from "config/moment"
import { ConfirmationViewColumnInterface, ConfirmationViewInterface } from "@type/global.types"
import { Table } from "antd-v5"
import React from "react"

interface Props {
	views?: ConfirmationViewInterface[]
}

const ConfirmationModalBody: React.FC<Props> = ({ views }) => {
	if (!views?.length) return <></>
	return (
		<div className="flex w-full flex-1 flex-col space-y-3">
			{[...views]
				.sort((a, b) => {
					return (a?.order ?? 1) - (b?.order ?? 1)
				})
				.map(({ type, data, columns = [] }, index) => {
					if (type === "text") return <span key={`confirmation_view_text_${index}`}>{data}</span>
					if (type === "table")
						return (
							<ConfirmationTable key={`confirmation_view_table_${index}`} data={data} columns={columns} />
						)
				})}
		</div>
	)
}

export default ConfirmationModalBody

const ConfirmationTable = ({ columns, data }: { columns: ConfirmationViewColumnInterface[]; data: any[] }) => {
	return (
		<>
			<Table
				size="small"
				pagination={false}
				scroll={{ y: 300 }}
				dataSource={data}
				rowClassName={"text-sm odd:bg-white even:bg-slate-50"}
				columns={columns.map((item: ConfirmationViewColumnInterface) => {
					let render: any = null

					switch (item.type) {
						case "date":
							render = (value: string) => (
								<div>{momentZ(value).format(DATE_FORMATS.DD_MM_YYYY_HH_MM)}</div>
							)
							break
						case "string":
							render = (value: string) => <div className="w-fit">{value}</div>
							break
					}

					return {
						className: "!p-2",
						title: item.title,
						key: item.key,
						dataIndex: item.dataIndex,
						...(!!render && { render }),
					}
				})}
			/>
		</>
	)
}
