import { GetPostsInterface } from "@type/locations.types"
import {
	CreateRotaPlannerInterface,
	RotaPlanTimes,
	RotaPlannerInterface,
	UpdateRotaPlan,
} from "@type/rotaPlanner.types"
import axios from "axios"

const ROOT_PATH = "/rota-plan"

export default class RotaPlannerService {
	static fetchRotaPlan = (contractId: string) =>
		axios.get<RotaPlannerInterface | null>(`${ROOT_PATH}/times/${contractId}`)

	static createRotaPlan = (payload: CreateRotaPlannerInterface) =>
		axios.post<RotaPlannerInterface | null>(`${ROOT_PATH}`, payload)

	static updateRotaPlanTimes = (contractId: string, payload: UpdateRotaPlan[]) =>
		axios.put<RotaPlanTimes[]>(`${ROOT_PATH}/times/${contractId}`, { data: payload })

	static createInactiveSchedulesFromRotaLoad = (payload: {
		contract: string
		location: string
		startTime: string
		finishTime: string
	}) => axios.post<GetPostsInterface[]>(`${ROOT_PATH}/load-rota`, { ...payload })

	static deleteInactiveSchedulePermanently = (contract: string, unloadFrom: string) =>
		axios.put<GetPostsInterface[]>(`inactive-schedules/delete/unload-rota`, { unloadFrom, contract })
}
