import axios from "axios"
import { uploadFileInterface } from "./type"

const ROOT_PATH = "media"

const newInstance = axios.create()

export default class GenericService {
	static upload = (files: File[] | File) => {
		const formData = new FormData()
		if (Array.isArray(files)) files.forEach(file => formData.append("file", file))
		else formData.append("file", files as File)
		return axios.post<uploadFileInterface>(`${ROOT_PATH}/upload/multiple`, formData)
	}

	static getSignedUrl = (path: string) => {
		return axios.post(`${ROOT_PATH}/url`, { path })
	}

	// static getBlobResponse = (url: string): Promise<Blob | MediaSource> =>
	// 	new Promise((resolve, reject) => {
	// 		console.warn("getBlobResponse")
	// 		fetch(url, { mode: "no-cors", method: "GET" })
	// 			.then(response => {
	// 				const contentType = response.headers.get("content-type")
	// 				console.warn("contentType: ", contentType)
	// 				return response.blob()
	// 			})
	// 			.then(blobData => {
	// 				console.warn("blobData: ", blobData.type)
	// 				// const contentType = blobData.type
	// 				// const newBlob = new Blob([blobData], { type: contentType })
	// 				resolve(blobData)
	// 			})
	// 			.catch(() => reject())
	// 	})

	static getBlobResponse = (url: string) =>
		newInstance.get(url, {
			responseType: "blob",
			headers: {},
		})
}

// const blobData = new Blob([response], {
// 	type: response.headers["content-type"],
// })
