import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { rotaPlannerService } from "services"
import { AppThunk, RootState } from "store"
import {
	CreateRotaPlannerInterface,
	RotaPlannerInterface,
	RotaPlanTimes,
	UpdateRotaPlan,
} from "@type/rotaPlanner.types"
import { chain, orderBy } from "lodash"
import { updateState } from "config/utils"

interface RotaPlannerStateInterface {
	list: RotaPlannerInterface | null
	loading: boolean
}

const initialState: RotaPlannerStateInterface = {
	list: null,
	loading: false,
}

const rotaPlannerSlice = createSlice({
	name: "rotaPlanner",
	initialState,
	reducers: {
		fetchingRotaPlanner: state => {
			state.loading = true
		},
		rotaPlannerUpdate: (state, action: PayloadAction<RotaPlanTimes[]>) => {
			state.loading = false
			if (state.list) {
				const stateToUpdate = state.list?.planTimes
				const [updatedState] = updateState(stateToUpdate, action.payload, "_id")
				state.list.planTimes = updatedState
			}
		},
		rotaPlannerFetchingFailed: state => {
			state.loading = false
		},
		savingRotaPlanner: payGroupData => {
			payGroupData.loading = true
		},
		rotaPlannerSaved: (state, action: PayloadAction<RotaPlannerInterface | null>) => {
			state.loading = false
			state.list = action.payload
		},
		rotaPlannerSavingFailed: state => {
			state.loading = false
		},
		clearRotaPlannerState: state => {
			state.list = null
		},
	},
})

//REDUCER
export default rotaPlannerSlice.reducer

//ACTIONS
export const {
	fetchingRotaPlanner,
	rotaPlannerFetchingFailed,
	savingRotaPlanner,
	rotaPlannerUpdate,
	rotaPlannerSaved,
	rotaPlannerSavingFailed,
	clearRotaPlannerState,
} = rotaPlannerSlice.actions

const fetchRotaPlan =
	(contractId: string, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			if (contractId) {
				dispatch(fetchingRotaPlanner())
				const { data: rotaPlannerResponse } = await rotaPlannerService.fetchRotaPlan(contractId)
				dispatch(rotaPlannerSaved(rotaPlannerResponse))
				cb?.()
			} else dispatch(clearRotaPlannerState())
		} catch (error) {
			dispatch(rotaPlannerFetchingFailed())
		}
	}

const createRotaPlan =
	(payload: CreateRotaPlannerInterface, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingRotaPlanner())
			const { data: rotaPlannerResponse } = await rotaPlannerService.createRotaPlan(payload)
			dispatch(rotaPlannerSaved(rotaPlannerResponse))
			cb?.()
		} catch (error) {
			dispatch(rotaPlannerSavingFailed())
		}
	}

const updateRotaPlanTimes =
	(contractId: string, payload: UpdateRotaPlan[], cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingRotaPlanner())
			const { data: rotaPlannerResponse } = await rotaPlannerService.updateRotaPlanTimes(contractId, payload)
			dispatch(rotaPlannerUpdate(rotaPlannerResponse))
			cb?.()
		} catch (error) {
			dispatch(rotaPlannerSavingFailed())
		}
	}

export { fetchRotaPlan, createRotaPlan, updateRotaPlanTimes }

//SELECTORS
const selectRotaPlannerState = (state: RootState) => state.rotaPlanner
const isRotaPlannerLoading = () => (state: RootState) => selectRotaPlannerState(state).loading
const selectRotaPlannerData = createSelector(
	(state: RootState) => state?.rotaPlanner.list,
	list => list?.rotaPlan,
)

// const selectTransformRotaPlannerData = createSelector(
// 	(state: RootState) => state?.rotaPlanner.list,
// 	list =>
// 		chain(list?.planTimes || [])
// 			.groupBy("post")
// 			.map((value, key) => ({
// 				postId: key,
// 				guards: Object.values(chain(value).groupBy("guardNumber").value()),
// 				data: orderBy(value, ["week", "day", "guardNumber"], ["asc", "asc", "asc"]),
// 			}))
// 			.value(),
// )

interface ColumnInterFace {
	title: string
	key: string
	dataIndex: string
	post: string
	guardNumber: string
	postName: string
	pg: string
}
interface MappedColumnsInterface {
	postName: string
	post: string
	guards: { guardNumber: string; columns: ColumnInterFace[] }[]
}
const selectTransformRotaPlannerData = createSelector(
	(state: RootState) => state?.rotaPlanner.list as RotaPlannerInterface,
	list => {
		if (!list) return { dataSource: [], columns: [] }
		const columns: ColumnInterFace[] = []

		const keyToTitle: { [key: string]: string } = {
			startTime: "Start",
			finishTime: "Finish",
			employee: "Employee",
		}

		const { planTimes = [] } = list
		let dataSource = planTimes.map(item => {
			Object.keys(keyToTitle).forEach(key => {
				const madeKey = `${key}_${item.guardNumber}_${item.post}`
				if (!columns.some(item => item.key === madeKey))
					columns.push({
						title: keyToTitle[key],
						key: madeKey,
						dataIndex: madeKey,
						post: item.post,
						guardNumber: "" + item.guardNumber,
						postName: item.postName,
						pg: `p${item.post}g${item.guardNumber}`,
					})
			})

			const data: any = {
				...item,
				weekDayKey: `w${item.week}d${item.day}`,
				[`_id_${item.guardNumber}_${item.post}`]: item._id,
				...Object.fromEntries(
					Object.keys(keyToTitle).map(key => [
						`${key}_${item.guardNumber}_${item.post}`,
						item?.[key as keyof RotaPlanTimes],
					]),
				),
			}
			delete data._id
			delete data.startTime
			delete data.finishTime
			delete data.employee
			delete data.guardNumber
			delete data.post
			delete data.createdAt
			delete data.updatedAt
			return data
		})

		dataSource = orderBy(
			chain(dataSource)
				.groupBy("weekDayKey")
				.map(value => value.reduce((acc, curr) => ({ ...acc, ...curr }), {}))
				.value(),
			["week", "day"],
		)

		const mappedColumns: MappedColumnsInterface[] = chain(columns)
			.groupBy("post")
			.map(value => ({
				postName: value[0].postName,
				post: value[0].post,
				guards: chain(value)
					.groupBy("guardNumber")
					.map((value, key) => ({ guardNumber: key, columns: value }))
					.value(),
			}))
			.value()

		return { dataSource, columns: mappedColumns }
	},
)

export { isRotaPlannerLoading, selectRotaPlannerData, selectTransformRotaPlannerData }
