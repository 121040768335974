import { useAppDispatch, useAppSelector } from "hooks"
import { selectPostsByNameForSchedules } from "store/Location/locationPosts.slice"
import { AntdButton, AntdEmpty, AntdInput, Modal } from "components"
import React, { useEffect, useState } from "react"
import { Divider } from "antd-v5"
import { updateState } from "config/utils"
import { CreateRotaPlannerInterface } from "@type/rotaPlanner.types"
import { createRotaPlan, isRotaPlannerLoading } from "store/RotaPlanner/rotaPlanner.slice"
import { isLocationContractsLoading } from "store/Location/locationContracts.slice"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	contractId: string
}

const RotaPlannerModal: React.FC<Props> = ({ open, modalHandler, contractId }) => {
	const dispatch = useAppDispatch()
	const selectedContractPosts = useAppSelector(selectPostsByNameForSchedules(contractId))
	const isContractsLoading = useAppSelector(isLocationContractsLoading())
	const isRotaPlanLoading = useAppSelector(isRotaPlannerLoading())

	const defaultState = {
		weeksInRotation: 1,
		postConfigs: selectedContractPosts.map(post => ({ postId: post.post, guardCount: 1 })),
		contract: contractId,
	}

	const [payload, setPayload] = useState<CreateRotaPlannerInterface>(defaultState)

	useEffect(() => {
		setPayload(prev => ({ ...prev, contract: contractId }))
	}, [contractId])

	const handleSubmit = (payload: CreateRotaPlannerInterface) => {
		dispatch(createRotaPlan(payload, () => modalHandler(false)))
	}

	return (
		<Modal
			title={<div className="font-semi pt-2.5 pb-4 text-center text-lg font-semibold">Create ROTA Plan</div>}
			containerClass="min-w-[600px]"
			open={open}
			modalHandler={(close: boolean) => {
				modalHandler(close)
				setPayload(defaultState)
			}}
		>
			<p>Number of Guards in Each Post</p>
			{selectedContractPosts.length ? (
				<div className="grid grid-cols-2 gap-x-3">
					{selectedContractPosts.map((post, index) => {
						return (
							<AntdInput
								key={post.post}
								min={1}
								defaultValue={1}
								value={payload.postConfigs[index]?.guardCount || 1}
								type="number"
								onChange={e => {
									const updatedPayload = { postId: post.post, guardCount: +e }

									setPayload(prev => ({
										...prev,
										postConfigs: updateState(prev.postConfigs, updatedPayload, "postId")[0],
									}))
								}}
								label={post.label}
							/>
						)
					})}
				</div>
			) : (
				<AntdEmpty description="No Posts Found" />
			)}

			<Divider />
			<AntdInput
				label="Weeks in Roster"
				min={1}
				defaultValue={1}
				type="number"
				value={payload.weeksInRotation || 1}
				onChange={value => {
					if (Number(value) > 0) setPayload(prev => ({ ...prev, weeksInRotation: Number(value) }))
				}}
			/>

			<div className="flex justify-end space-x-2 pt-4">
				<AntdButton
					onClick={() => {
						modalHandler(false)
						setPayload(defaultState)
					}}
				>
					Cancel
				</AntdButton>
				<AntdButton
					loading={isContractsLoading || isRotaPlanLoading}
					disabled={!selectedContractPosts.length}
					onClick={() => handleSubmit(payload)}
				>
					Create
				</AntdButton>
			</div>
		</Modal>
	)
}

export default RotaPlannerModal
