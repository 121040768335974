import { ShiftInterface } from "@type/workMonitor.types"
import { createContext } from "react"

export interface ShiftEventsContextProps {
	shift: ShiftInterface | null
	fetchShiftByScheduleId: (scheduleId: string) => void
}

const ShiftEventsRetainContext = createContext<ShiftEventsContextProps>({
	shift: null,
	fetchShiftByScheduleId: () => undefined,
})

export default ShiftEventsRetainContext
