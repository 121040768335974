import { checkIfTextExists, sortByProperty, updateState } from "config/utils"
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit"
import {
	GetLocationDetailsInterface,
	CreateLocationDetailsInterface,
	GetAllLocationDetailsInterface,
	GetLocationQRCodeInterface,
	LocationStats,
	GetLocationStats,
} from "@type/locations.types"
import { locationDetails, locationQRCode } from "services"
import { AppThunk, RootState } from "store"
import { lowerCase, uniqBy } from "lodash"
import { clearSchedule } from "store/Schedules/schedules.slice"
import { clearPostList } from "./locationPosts.slice"
import { clearContractList } from "./locationContracts.slice"
import { setSelectedLocation, setSelectedLocationBranch } from "store/globalState.slice"
// import { clearSelectedLocation } from "store/globalState.slice"

export interface LocationDetailsStateInterface {
	list: Array<GetLocationDetailsInterface>
	allLocationList: Array<GetAllLocationDetailsInterface>
	loading: boolean
	saving: boolean
	newDetail: null | GetLocationDetailsInterface
	stats: LocationStats | null
	statsLoading: boolean
	addLocationClicked: boolean
}

const initialState: LocationDetailsStateInterface = {
	list: [],
	allLocationList: [],
	loading: false,
	saving: false,
	newDetail: null,
	stats: null,
	statsLoading: false,
	addLocationClicked: false,
}

const locationDetailsSlice = createSlice({
	name: "locationDetails",
	initialState,
	reducers: {
		fetchingLocations: location => {
			location.loading = true
		},
		locationsFetched: (location, action: PayloadAction<GetLocationDetailsInterface[]>) => {
			location.loading = false
			location.list = uniqBy([...location.list, ...action.payload.sort(sortByProperty("name"))], "_id")
		},
		allLocationsFetched: (location, action: PayloadAction<GetAllLocationDetailsInterface[]>) => {
			location.loading = false
			location.allLocationList = action.payload.sort(sortByProperty("name"))
		},
		locationsFetchingFailed: location => {
			location.loading = false
		},
		savingLocation: locationData => {
			locationData.saving = true
		},
		locationSaved: (location, action: PayloadAction<GetLocationDetailsInterface>) => {
			location.saving = false

			let updatedResponse: any = null

			if (location.addLocationClicked && action.payload.shouldSelect) {
				updatedResponse = updateState(location.list, action.payload, "_id")
			} else if (!location.addLocationClicked) {
				updatedResponse = updateState(location.list, action.payload, "_id")
			}

			if (updatedResponse) {
				const [updatedValue] = updatedResponse
				location.list = updatedValue.sort(sortByProperty("name"))
			}
		},
		locationSavingFailed: location => {
			location.saving = false
		},
		locationAddingEventClicked: (location, action: PayloadAction<boolean>) => {
			location.addLocationClicked = action.payload
		},
		ClearNewDetail: location => {
			location.newDetail = null
		},
		ClearLocationDetailsState: location => {
			location.list = []
			location.loading = false
			location.saving = false
			location.allLocationList = []
			location.newDetail = null
		},
		fetchingLocationStats: location => {
			location.statsLoading = true
		},
		fetchedLocationStats: (location, action: PayloadAction<LocationStats>) => {
			location.statsLoading = false
			location.stats = action.payload
		},
		fetchedLocationStatsFailed: location => {
			location.statsLoading = false
		},
	},
})

//REDUCER
export default locationDetailsSlice.reducer

//ACTIONS
export const {
	allLocationsFetched,
	fetchingLocations,
	locationsFetched,
	locationsFetchingFailed,
	savingLocation,
	locationSaved,
	locationSavingFailed,
	locationAddingEventClicked,
	ClearNewDetail,
	ClearLocationDetailsState,
	fetchingLocationStats,
	fetchedLocationStats,
	fetchedLocationStatsFailed,
} = locationDetailsSlice.actions

const getLocations = (): AppThunk => async dispatch => {
	try {
		dispatch(fetchingLocations())
		const { data: locationResponse } = await locationDetails.getAllLocations()
		dispatch(locationsFetched(locationResponse))
	} catch (error) {
		dispatch(locationsFetchingFailed())
	}
}

const getAllLocations = (): AppThunk => async dispatch => {
	try {
		dispatch(fetchingLocations())
		const { data: locationResponse } = await locationDetails.getAllLocationsDetail()
		dispatch(allLocationsFetched(locationResponse))
	} catch (error) {
		dispatch(locationsFetchingFailed())
	}
}

const getLocationsByBranchId =
	(branchId: string): AppThunk =>
	async (dispatch, getState) => {
		try {
			if (
				getState().location.details.loading ||
				getState().location.details.list?.some(item => item?.branch?._id === branchId)
			)
				return

			dispatch(fetchingLocations())
			dispatch(clearContractList())
			// dispatch(clearSelectedLocation())
			dispatch(clearPostList())
			dispatch(clearSchedule())
			const { data: locationResponse } = await locationDetails.getLocationByBranchId(branchId)
			dispatch(locationsFetched(locationResponse))
		} catch (error) {
			dispatch(locationsFetchingFailed())
		}
	}

const saveLocation =
	(locationData: Partial<GetLocationDetailsInterface>): AppThunk =>
	async dispatch => {
		let data = null
		let shouldSelect = false
		try {
			dispatch(savingLocation())
			if (locationData._id) data = await locationDetails.updateLocationDetails(locationData._id, locationData)
			else {
				data = await locationDetails.createLocation(locationData as CreateLocationDetailsInterface)
				shouldSelect = true
			}
			const { data: locationResponse } = data

			if (shouldSelect) {
				dispatch(getLocationsByBranchId(locationResponse.branch?._id as string))
				dispatch(setSelectedLocationBranch(locationResponse.branch?._id as string))
				dispatch(setSelectedLocation(locationResponse._id))
				dispatch(locationSaved({ ...locationResponse, shouldSelect }))
			}
		} catch (error) {
			dispatch(locationSavingFailed())
		}
	}

const saveLocationQRCode =
	(id: string, locationQRCodeData: Partial<GetLocationQRCodeInterface>): AppThunk =>
	async (dispatch, getState) => {
		let data = null
		const selectedLocation = getState().globalState.selectedLocation
		const selectedLocationDetail = (getState()?.location.details?.list || []).find(
			({ _id = "" }) => _id === selectedLocation,
		)
		try {
			dispatch(savingLocation())
			if (locationQRCodeData._id) {
				data = await locationQRCode.updateLocationQRCode(id, {
					...locationQRCodeData,
					isLocationActive: selectedLocationDetail?.isActive || true,
				})
			} else {
				data = await locationQRCode.createLocationQRCode(id, {
					...locationQRCodeData,
					isLocationActive: selectedLocationDetail?.isActive || true,
				})
			}

			const { data: locationQRCodeResponse } = data

			dispatch(locationSaved(locationQRCodeResponse))
		} catch (error) {
			dispatch(locationSavingFailed())
		}
	}
const deleteLocationQRCodeById =
	(id: string, qrId: string): AppThunk =>
	async (dispatch, getState) => {
		try {
			const selectedLocation = getState().globalState.selectedLocation
			const selectedLocationDetail = (getState()?.location.details?.list || []).find(
				({ _id = "" }) => _id === selectedLocation,
			)

			dispatch(savingLocation())
			const { data: qualificationResponse } = await locationQRCode.deleteLocationQRCode(
				id,
				qrId,
				selectedLocationDetail?.isActive,
			)
			dispatch(locationSaved(qualificationResponse))
		} catch (error) {
			dispatch(locationSavingFailed())
		}
	}

const getLocationStats =
	(payload: GetLocationStats): AppThunk =>
	async dispatch => {
		const { date, locationIds } = payload
		try {
			dispatch(fetchingLocationStats())
			const { data: locationStats } = await locationDetails.getLocationStats({ locationIds, date })
			dispatch(fetchedLocationStats(locationStats[0] ?? null))
		} catch (error) {
			dispatch(fetchedLocationStatsFailed())
		}
	}

const selectSelectedLocationData = createSelector(
	(state: RootState) => ({
		selectedLocation: state.globalState.selectedLocation,
		locationList: state.location.details.list,
	}),
	({ selectedLocation = "", locationList }) => locationList.find(({ _id }) => _id === selectedLocation),
)

export {
	getAllLocations,
	getLocations,
	saveLocation,
	getLocationsByBranchId,
	saveLocationQRCode,
	deleteLocationQRCodeById,
	getLocationStats,
}

//SELECTORS
const selectLocationState = (state: RootState) => state.location.details
const isLocationLoading = () => (state: RootState) => selectLocationState(state).loading
const selectLocationList = () => (state: RootState) => selectLocationState(state).list
const isSavingLocation = () => (state: RootState) => selectLocationState(state).saving
const selectNewLocationDetailAdded = () => (state: RootState) => selectLocationState(state).newDetail
const selectSelectedLocationStats = () => (state: RootState) => selectLocationState(state).stats
const selectSelectedLocationTrends = () => (state: RootState) => selectLocationState(state).stats?.trends
const selectLocationStatsLoading = () => (state: RootState) => selectLocationState(state).statsLoading
const selectAddLocationClicked = () => (state: RootState) => selectLocationState(state).addLocationClicked

const selectLocationsForSideBar = () => (state: RootState) =>
	selectLocationState(state)
		.list?.map(({ _id, name }) => ({
			_id,
			name: name,
		}))
		.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

const selectLocationName = (locationId: string) =>
	createSelector(
		(state: RootState) => state.location.details.list,
		locationList => locationList?.find(i => i._id == locationId),
	)

const selectLocationListByBranchId = (branchId: string) =>
	createSelector(
		(state: RootState) => state.location.details.list,
		locationList => locationList?.filter(i => i?.branch?._id == branchId),
	)

const selectLocationListByField = (fieldName: string, fieldValue: string) => {
	return createSelector(
		(state: RootState) => state.location.details.allLocationList,
		locationList =>
			lowerCase(fieldName) && fieldValue
				? locationList?.filter(
						item => item?.[lowerCase(fieldName) as keyof GetAllLocationDetailsInterface] === fieldValue,
				  )
				: [],
	)
}

const selectLocationById = (locationId: string) => (state: RootState) =>
	selectLocationList()(state)?.find(location => location._id === locationId)

const filteredListBySearch = (
	searchOn: string,
	branchId: string,
	searchOnFields: string[],
	customerId?: string,
	shouldShowActiveOnly?: boolean,
) => {
	return createSelector(
		(state: RootState) => state.location.details.list,
		locationList => {
			const sortedLocationList = checkIfTextExists(
				locationList?.filter((i: any) => {
					if (!customerId) {
						return i.branch._id === branchId
					}
					return i?.branch?._id === branchId && i?.customer?._id === customerId
				}),
				searchOn,
				searchOnFields,
			).sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
			if (shouldShowActiveOnly) return sortedLocationList.filter((item: any) => !!item?.isActive)
			return sortedLocationList
		},
	)
}
const selectQRCodeByLocationId = (locationId: string | null) => (state: RootState) =>
	selectLocationList()(state)?.find(location => location._id === locationId)?.qrCode

export {
	filteredListBySearch,
	selectLocationName,
	selectLocationListByBranchId,
	selectLocationList,
	selectLocationsForSideBar,
	selectLocationState,
	isSavingLocation,
	selectLocationById,
	isLocationLoading,
	selectNewLocationDetailAdded,
	selectLocationListByField,
	selectQRCodeByLocationId,
	selectSelectedLocationStats,
	selectLocationStatsLoading,
	selectAddLocationClicked,
	selectSelectedLocationTrends,
	selectSelectedLocationData,
}
