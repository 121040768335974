import React from "react"
import { Icon } from "components"
import { twMerge } from "tailwind-merge"

interface Props {
	children: JSX.Element[] | JSX.Element
	open: boolean
	title?: JSX.Element | string
	modalHandler: (open: boolean) => void
	goBack?: boolean
	containerClass?: string
}

const Modal: React.FC<Props> = ({
	children,
	open = false,
	title,
	modalHandler,
	goBack = false,
	containerClass = "max-w-full",
}) => {
	if (!open) return null

	return (
		<div className="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
			<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

			<div
				onClick={e => {
					e.stopPropagation()
					if (!window.getSelection()?.toString()) {
						if (!goBack) modalHandler(false)
						document.body.style.overflow = "unset"
					}
				}}
				className="fixed inset-0 z-10 overflow-y-auto"
			>
				<div className="flex min-h-full items-end justify-center  p-4 text-center sm:items-center sm:p-0">
					<div
						onClick={e => {
							e.stopPropagation()
						}}
						className={twMerge(
							`overflow-y-auto rounded-lg bg-white text-left shadow-xl transition-all sm:my-8`,
							containerClass,
						)}
					>
						<div className=" bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
							<div
								className={`flex w-auto ${goBack ? "justify-between" : "justify-end"} cursor-pointer `}
							>
								{goBack ? (
									<div onClick={() => modalHandler(false)}>
										<Icon name="leftArrow" color="black" size={18} />
									</div>
								) : (
									<div className="w-fit" onClick={() => modalHandler(false)}>
										<Icon name="cross" color="black" size={18} />
									</div>
								)}
							</div>
							<div className="mt-3 text-center sm:mt-0  sm:text-left">
								{React.isValidElement(title) ? (
									title
								) : (
									<h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
										{title}
									</h3>
								)}
							</div>
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Modal
