import {
	AntdButton,
	Icon,
	SignedUrlForImage,
	// Image
} from "components/index"

import { IncidentInterface } from "@type/workMonitor.types"
import React, { useState } from "react"
import moment from "moment"
import { PDFDocument, PDFPage, StandardFonts, rgb } from "pdf-lib"
import { lgLogo } from "images"
import { addPathToDocument } from "config/utils"
// import { DownloadOutlined } from "@ant-design/icons"
import { Tooltip, Image } from "antd-v5"
import { CloudDownloadOutlined } from "@ant-design/icons"

interface Props {
	incidents: IncidentInterface[]
}

const convertStringToEqualSizedArrayOfStrings = (input: string, size: number): string[] => {
	const words = input.split(" ")
	const lines: string[] = []

	let currentLine = ""

	for (const word of words) {
		const potentialLine = `${currentLine}${currentLine ? " " : ""}${word}`

		if (potentialLine.length <= size) {
			currentLine = potentialLine
		} else {
			lines.push(currentLine)
			currentLine = word
		}
	}

	if (currentLine) {
		lines.push(currentLine)
	}

	return lines
}
export const generatePDF = async (incident: IncidentInterface) => {
	const pdfDoc = await PDFDocument.create()
	const page = pdfDoc.addPage()
	const data = incident
	const fontBold = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)
	const font = await pdfDoc.embedFont(StandardFonts.TimesRoman)
	const styles = {
		logo: { x: 260, y: 770, width: 80, height: 65 },
		// logo: { x: 230, y: 770, width: 145, height: 45 }, Agile Form
		title: { x: 235, y: 743, fontSize: 14, font: fontBold },
		borderBox: { x: 74, y: 589, width: 451, height: 130 },
		detailsBox: { x: 72, y: 198, width: 451, height: 365 },
		incidentText: { x: 79, y: 708, fontSize: 11, font },
		officerName: { x: 79, y: 677, fontSize: 11, font },
		date: { x: 79, y: 652, fontSize: 11, font },
		policeRequested: { x: 79, y: 627, fontSize: 11, font },
		actionTaken: { x: 79, y: 602, fontSize: 11, font },
		siteName: { x: 305, y: 677, fontSize: 11, font },
		time: { x: 305, y: 652, fontSize: 11, font },
		reportNo: { x: 305, y: 627, fontSize: 11, font },
		controlInformed: { x: 305, y: 602, fontSize: 11, font },
		details: { x: 81, y: 525, fontSize: 11, font },
		reportingOfficer: { x: 72, y: 159, fontSize: 11, font },
		signature: { x: 72, y: 138, fontSize: 11, font },
		signatureImage: { x: 155, y: 130, width: 120, height: 30 },
		reportingDate: { x: 385, y: 138, fontSize: 11, font },
		eventTime: { x: 415, y: 138, fontSize: 11, font },
		footer: { x: 72, y: 40, fontSize: 9, font },
	}

	const imageBytes = await fetch(lgLogo).then(res => res.arrayBuffer())
	const logoImage = await pdfDoc.embedJpg(imageBytes)
	// const logoImage = await pdfDoc.embedPng(imageBytes)

	type styleName =
		| "title"
		| "incidentText"
		| "officerName"
		| "date"
		| "policeRequested"
		| "actionTaken"
		| "siteName"
		| "time"
		| "reportNo"
		| "controlInformed"
		| "details"
		| "signature"
		| "reportingOfficer"
		| "reportingDate"
		| "eventTime"
		| "footer"

	const textFields: { val: string; prop: styleName }[] = [
		{ val: "Incident Report Form", prop: "title" },
		{ val: "Incident: " + data.incident, prop: "incidentText" },
		{ val: "Security Officer Name: " + (data?.officerName || "N/A"), prop: "officerName" },
		{ val: "Date of incident: " + moment(data.date).format("DD-MM-YYYY"), prop: "date" },
		{ val: `Police Requested: ${data.policeRequested ? "Yes" : "No"}`, prop: "policeRequested" },
		{ val: `Action taken: ${data.actionTaken ? "Yes" : "No"}`, prop: "actionTaken" },
		{ val: "Site Name: " + (data.siteName || ""), prop: "siteName" },
		{ val: "Time of Incident: " + moment(data.date).format("hh:mm"), prop: "time" },
		{ val: "Report No / Reference: " + (data?.reference || "N/A"), prop: "reportNo" },
		{ val: `Control Informed: ${data.actionTaken ? "Yes" : "No"}`, prop: "controlInformed" },
		{ val: "Details of Incident: ", prop: "details" },
		{ val: "Signature: __________________________________", prop: "signature" },
		{
			val:
				"Reporting Officer Name: " +
				(data?.reportingOfficer?.firstName || "N/A") +
				" " +
				(data?.reportingOfficer?.lastName || ""),
			prop: "reportingOfficer",
		},
		{ val: "Date: ___/___/______", prop: "reportingDate" },
		{ val: moment(data.eventTime).format("DD   MM    YYYY"), prop: "eventTime" },
		{ val: "Doc No: QBC.30, Issue Date: 01/06/2021, Issue: 4", prop: "footer" },
	]

	page.drawImage(logoImage, {
		x: styles.logo.x,
		y: styles.logo.y,
		width: styles.logo.width,
		height: styles.logo.height,
	})

	page.drawRectangle({
		x: styles.borderBox.x,
		y: styles.borderBox.y,
		width: styles.borderBox.width,
		height: styles.borderBox.height,
		borderWidth: 0.1,
		borderColor: rgb(0, 0, 0),
	})
	page.drawRectangle({
		x: styles.detailsBox.x,
		y: styles.detailsBox.y,
		width: styles.detailsBox.width,
		height: styles.detailsBox.height,
		borderWidth: 0.1,
		borderColor: rgb(0, 0, 0),
	})

	const horizontalLines = [688, 663, 638, 613]
	horizontalLines.map(val => {
		page.drawLine({
			start: { x: 75, y: val },
			end: { x: 525, y: val },
			thickness: 0.5,
			color: rgb(0, 0, 0),
			opacity: 1,
		})
	})

	page.drawLine({
		start: { x: 300, y: 688 },
		end: { x: 300, y: 589 },
		thickness: 0.5,
		color: rgb(0, 0, 0),
		opacity: 1,
	})

	const horizontalLinesDetails = [501, 475.75, 450.5, 425.25, 400, 374.75, 349.5, 324.25, 299, 273.75, 248.5, 223.25]
	const rowSize = 93
	const detailsData = convertStringToEqualSizedArrayOfStrings(incident.details, rowSize)

	horizontalLinesDetails.map((val, index) => {
		page.drawLine({
			start: { x: 80, y: val },
			end: { x: 516, y: val },
			thickness: 0.5,
			color: rgb(0, 0, 0),
			opacity: 1,
		})
		page.drawText(detailsData[index] ?? "", {
			x: 84,
			y: val + 2,
			size: styles.incidentText.fontSize,
			font: styles.incidentText.font,
			color: rgb(0, 0, 0),
		})
	})
	textFields.forEach(({ val, prop }) => {
		page.drawText(val, {
			x: styles[prop].x,
			y: styles[prop].y,
			size: styles[prop].fontSize,
			font: styles[prop].font,
			color: rgb(0, 0, 0),
		})
	})
	if (data.signature) {
		const signatureBytes = await fetch(await addPathToDocument(data.signature)).then(res => res.arrayBuffer())
		const signatureImage = await pdfDoc.embedPng(signatureBytes)
		page.drawImage(signatureImage, {
			x: styles.signatureImage.x,
			y: styles.signatureImage.y,
			width: styles.signatureImage.width,
			height: styles.signatureImage.height,
		})
	}

	if (data.images.length) {
		const imageStyles = [
			{ x: 70, y: 385 },
			{ x: 350, y: 385 },
			{ x: 70, y: 60 },
			{ x: 350, y: 60 },
		]

		const imagesPromises = data.images.map(async image => {
			const bytes = await fetch(await addPathToDocument(image)).then(res => res.arrayBuffer())
			return await pdfDoc.embedJpg(bytes)
		})
		const images = await Promise.all(imagesPromises)
		let newPage: PDFPage
		images.forEach((imageData, index) => {
			const targetHeight = 320
			const targetWidth = 180
			if (index % 4 === 0) {
				newPage = pdfDoc.addPage()
				newPage.drawImage(logoImage, {
					x: styles.logo.x,
					y: styles.logo.y,
					width: styles.logo.width,
					height: styles.logo.height,
				})
				newPage.drawText("Incident Report Form", {
					x: styles.title.x,
					y: styles.title.y,
					size: styles.title.fontSize,
					font: styles.title.font,
					color: rgb(0, 0, 0),
				})
				newPage.drawText("Doc No: QBC.30, Issue Date: 01/06/2021, Issue: 4", {
					x: styles.footer.x,
					y: styles.footer.y,
					size: styles.footer.fontSize,
					font: styles.footer.font,
					color: rgb(0, 0, 0),
				})
			}
			newPage.drawImage(imageData, {
				x: imageStyles[index % 4].x,
				y: imageStyles[index % 4].y,
				width: targetWidth,
				height: targetHeight,
			})
		})
	}
	const pdfBytes = await pdfDoc.save()
	const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" })
	const pdfUrl = window.URL.createObjectURL(pdfBlob)
	const tempLink = document.createElement("a")
	tempLink.href = pdfUrl
	tempLink.setAttribute("download", "Report")
	tempLink.click()
}

const IncidentView: React.FC<Props> = ({ incidents }) => {
	if (!incidents.length) return <></>
	return (
		<div className="">
			<span className="w-full text-center text-lg font-semibold">Incidents</span>
			{incidents?.map((incident, index) => (
				<EntityView key={index} incident={incident} makeReport={generatePDF} />
			))}
		</div>
	)
}

const EntityView: React.FC<{ incident: IncidentInterface; makeReport: (incident: IncidentInterface) => void }> = ({
	incident,
	makeReport,
}) => {
	const [showDropDown, setShowDropDown] = useState<boolean>(false)
	const handleDropDown = () => {
		setShowDropDown(prev => !prev)
	}
	const googleMapsUrl = `https://www.google.com/maps?q=${incident.geo?.coordinates[0]},${incident.geo?.coordinates[1]}`

	return (
		<div className="m-2 border-[0.75px] px-2 pb-2 font-semibold">
			<div className="flex cursor-pointer justify-between pt-2">
				<div className="h-full w-full" onClick={handleDropDown}>
					<div className="flex">
						<div className="w-fit ">
							<Icon name={showDropDown ? "dropdownArrow" : "dropRightArrow"} size={20} color="black" />
						</div>
						<span className={`rounded-2 bg-red-600 px-2 font-bold text-white `}>{incident?.incident}</span>
					</div>

					<div className="flex space-x-5">
						<span className="col-span-4">Occurred at : {moment(incident.date).format("HH:mm")}</span>
					</div>
				</div>
				<Tooltip placement="top" title={"Download"}>
					<AntdButton
						title="Download Report"
						className="flex items-center justify-center"
						icon={<CloudDownloadOutlined />}
						onClick={() => makeReport(incident)}
					/>
				</Tooltip>
				{/* <AntdButton onClick={() => makeReport(incident)}>Download</AntdButton> */}
			</div>

			<div className={`flex flex-col space-y-2 px-2 py-2 ${showDropDown ? "visible" : " hidden"} `}>
				{!!incident.geo?.coordinates.length && (
					<span>
						Location:{" "}
						<a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
							View on map
						</a>
					</span>
				)}
				<span>Officer Name: {incident.officerName}</span>
				<span>
					Reporting Officer: {incident.reportingOfficer.firstName + " " + incident.reportingOfficer.lastName}
				</span>
				{!!incident.images?.length && (
					<div className={`grid grid-cols-4 gap-x-3 p-2 `}>
						<Image.PreviewGroup>
							{incident.images?.map((imageUrl, index) => {
								return (
									<SignedUrlForImage path={imageUrl} key={`loadedImage_${index}`}>
										{(image: string) => <Image key={`loadedImage_${index}`} src={image} />}
									</SignedUrlForImage>
								)
							})}
						</Image.PreviewGroup>
					</div>
				)}
			</div>
		</div>
	)
}

export default IncidentView
