import { useAppSelector, useHeightResize } from "hooks"
import { isRotaPlannerLoading, selectTransformRotaPlannerData } from "store/RotaPlanner/rotaPlanner.slice"
import { ConfigProvider, Table } from "antd-v5"
import { AntdInput, AntdSelect1 } from "components"
import { selectEmployeeDropDown } from "store/Employee/detail.slice"
import { forwardRef } from "react"
import { updateState } from "config/utils"

const { Column, ColumnGroup } = Table
const keys = ["_id", "startTime", "finishTime", "employee"]

const RotaPlannerTable = forwardRef((_, ref: any) => {
	useHeightResize({ element: "rota-planner-table-container" })
	const isRotaLoading = useAppSelector(isRotaPlannerLoading())
	const { dataSource, columns } = useAppSelector(selectTransformRotaPlannerData)
	const employeesList = useAppSelector(selectEmployeeDropDown())

	return (
		<ConfigProvider
			prefixCls="ant5"
			theme={{
				components: {
					Table: { cellPaddingBlockSM: 0, padding: 0 },
					Input: {
						paddingBlockSM: 0,
						borderRadiusSM: 0,
						colorBorder: "white",
						hoverBorderColor: "white",
						paddingInlineSM: 0,
						fontSize: 12,
					},
					Select: {
						paddingSM: 0,
						borderRadiusSM: 0,
						colorBorder: "white",
						optionFontSize: 12,
						fontSize: 12,
						optionPadding: 0,
						paddingContentVerticalSM: 0,
						paddingXS: 0,
					},
				},
			}}
		>
			<div id="rota-planner-table-container" className="schedule-scrollbar w-full overflow-auto">
				<Table
					className={`${dataSource.length && "w-fit"}`}
					bordered={true}
					size="small"
					rowKey="weekDayKey"
					dataSource={dataSource}
					pagination={false}
					loading={isRotaLoading}
				>
					<Column
						title="WeekDay"
						dataIndex="dayOfWeek"
						key="weekDayKey"
						rowScope="row"
						render={value => <div className="w-28">{value}</div>}
					/>
					{columns.map(item => {
						return (
							<ColumnGroup className="!text-center" key={`${item.post}`} title={item.postName}>
								{item.guards.map(({ guardNumber, columns }, guardIndex) => (
									<ColumnGroup
										className="!text-center"
										key={`${guardNumber}_${guardIndex}`}
										dataIndex={`${guardNumber}_${guardIndex}`}
										title={`Guard ${guardNumber}`}
									>
										{columns?.map(({ title, key, dataIndex }) => {
											const [_key, guardNumber, postId] = key.split("_")
											return (
												<Column
													width={100}
													title={title}
													key={key}
													dataIndex={dataIndex}
													className="!p-0 !pl-1 !text-sm"
													render={(value, row: any) => {
														const dataForCurrentDocument = Object.fromEntries(
															keys.map(key => [
																key,
																row?.[`${key}_${guardNumber}_${postId}`],
															]),
														)

														return title === "Employee" ? (
															<AntdSelect1
																size="small"
																defaultValue={value}
																className="w-32"
																options={employeesList.map(({ name, _id }) => ({
																	label: name,
																	value: _id,
																}))}
																onChange={value => {
																	dataForCurrentDocument[_key] = value
																	const [updatedState] = updateState(
																		ref?.current,
																		dataForCurrentDocument,
																		"_id",
																	)
																	ref.current = updatedState
																}}
															/>
														) : (
															<AntdInput
																size="small"
																type="text"
																className="w-10 "
																defaultValue={value}
																onChange={e => {
																	dataForCurrentDocument[_key] = e.target.value
																	const [updatedState] = updateState(
																		ref.current,
																		dataForCurrentDocument,
																		"_id",
																	)
																	ref.current = updatedState
																}}
															/>
														)
													}}
												/>
											)
										})}
									</ColumnGroup>
								))}
							</ColumnGroup>
						)
					})}
				</Table>
			</div>
		</ConfigProvider>
	)
})

export default RotaPlannerTable
