import React, { useEffect, useRef } from "react"
import { Select } from "antd"
import type { SizeType } from "antd/es/config-provider/SizeContext"
import { UseFormSetValue } from "react-hook-form"
import { BaseSelectRef } from "rc-select/lib/BaseSelect"
import { twMerge } from "tailwind-merge"
import InputLabel from "./InputLabel"

interface SelectOptions {
	label: string
	value: string
}

type mode = "multiple" | "tags"

interface Props {
	name: string
	label?: string
	mode?: mode | undefined
	formValueSetter?: UseFormSetValue<any>
	onChange?: (value: string | string[]) => void
	placeholder?: string
	disabled?: boolean
	selected?: any
	options: SelectOptions[]
	containerClass?: string
	className?: string
	maxTagLength?: number
	allowClear?: boolean
	defaultFocusOn?: boolean
	isControlled?: boolean
	size?: SizeType
	errors?: any
	loading?: boolean
}

const SelectField: React.FC<Props> = ({
	name,
	size = "small",
	label,
	placeholder = "Search",
	mode = undefined,
	disabled = false,
	selected = "",
	maxTagLength = 32,
	allowClear = false,
	options,
	formValueSetter,
	containerClass,
	className,
	onChange,
	defaultFocusOn = false,
	isControlled = false,
	errors = {},
	loading = false,
}) => {
	const inputRef = useRef<BaseSelectRef>(null)

	const onChangeLocal = (value: string) => {
		formValueSetter && formValueSetter(name, value, { shouldDirty: true })
	}

	useEffect(() => {
		if (defaultFocusOn) inputRef?.current?.focus()
	}, [])
	return (
		<div className={twMerge(`flex flex-col text-dark-alt`, containerClass)}>
			<InputLabel label={label || ""}></InputLabel>
			<Select
				allowClear={allowClear}
				mode={mode}
				ref={inputRef}
				showSearch
				className={className}
				placeholder={placeholder}
				optionFilterProp="children"
				defaultValue={selected}
				{...(isControlled ? { value: selected } : {})}
				onChange={onChange || onChangeLocal}
				filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
				options={options}
				disabled={disabled}
				size={size}
				maxTagCount={"responsive"}
				maxTagTextLength={maxTagLength}
				loading={loading}
			/>
			{errors[name]?.message && <span className="mt-1 ml-1 text-sm text-danger">{errors[name].message}</span>}
		</div>
	)
}

export default SelectField
