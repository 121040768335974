import { envConfig, theme } from "config"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Sidebar, NavBar } from "components"
import { NAVBAR_OPTION, NAVBAR_OPTIONS } from "./NavBar"
import { useAbac } from "react-abac"
import { RouteToFeatureFlag, RouteToPermissions } from "config/routes"
import LocationContext from "pages/locations/LocationContext"
import useAppDispatch from "hooks/useAppDispatch"
import { getEmployees } from "store/Employee/detail.slice"
import { isWorkMonitor } from "config/utils"
import LoadingBar from "react-top-loading-bar"
import GlobalConfirmationModal from "./GlobalConfirmationModal"
import NotFound from "pages/404/404"
import useFeatureFlags from "hooks/useFeatureFlags"

const SidebarLayout: React.FC = () => {
	const { pathname } = useLocation()
	const { userHasPermissions } = useAbac()
	const { featureFlagEnabled } = useFeatureFlags()
	const [panel, setPanel] = useState(envConfig.defaultView)
	const [active, setActive] = useState<number>(-1)
	const [showSidebar, setShowSidebar] = useState<boolean>(true)
	const [selected, setSelected] = useState<string>("")
	const dispatch = useAppDispatch()

	const ref = useRef<any>(null)

	useEffect(() => {
		dispatch(getEmployees())
	}, [])

	useEffect(() => {
		const splitPathName = pathname.split("/").slice(1)
		const activeTab = NAVBAR_OPTIONS[splitPathName[0]].findIndex(
			(item: NAVBAR_OPTION) => item.path === splitPathName?.[1],
		)
		setPanel(splitPathName[0])
		setActive(activeTab < 0 ? 0 : activeTab)
		ref?.current?.complete()
	}, [pathname, panel])

	const isCommandCenterRoute = useMemo(() => isWorkMonitor(pathname), [pathname])

	useEffect(() => {
		if (!isCommandCenterRoute) setShowSidebar(true)
		else setShowSidebar(false)
	}, [isCommandCenterRoute])

	const handleSelected = (locationId: string) => setSelected(locationId)

	const routePermission = useMemo(() => RouteToPermissions?.[pathname], [pathname])

	const handleShowSidebar = () => setShowSidebar(prev => !prev)

	if ((routePermission && !userHasPermissions(routePermission)) || !featureFlagEnabled(RouteToFeatureFlag[pathname]))
		return <NotFound />

	return (
		<LocationContext.Provider value={{ selected, handleSelected }}>
			<LoadingBar color={theme.colors.purple} ref={ref} />
			<GlobalConfirmationModal />
			<div className="flex h-screen w-full overflow-y-clip">
				<div className={`h-full w-72 bg-gray-800 ${!showSidebar ? "-ml-64 w-72" : ""}`}>
					<Sidebar
						setPanel={panel => {
							setPanel(panel)
							setActive(0)
						}}
						handleShowSidebar={handleShowSidebar}
						showSidebar={showSidebar}
					/>
				</div>

				<div className="flex-1 overflow-clip px-3 pb-2">
					<NavBar loaderRef={ref} panel={panel} active={active} />
					<div className="flex-1 overflow-clip pt-2">
						<Outlet />
					</div>
				</div>
			</div>
		</LocationContext.Provider>
	)
}

export default SidebarLayout
