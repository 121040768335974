import React, { useEffect, useMemo, useState } from "react"
import Location from "./Location/Location"
import Employee from "./Employee/Employee"
import useAppDispatch from "hooks/useAppDispatch"
import { scheduleSaved } from "store/Schedules/schedules.slice"
import SchedulesFilter from "./Filter/SchedulesFilter"
import { getBranches } from "store/Branches/branch.slice"
import { socket } from "config/socket"
import { ROOM_EVENTS, SOCKET_ROOMS, SCHEDULE_SOCKET_EVENTS } from "types/webSocket.types"
import { Permissions } from "config/constants"
import { getSDSuppliers } from "store/StaticData/SDSuppliers.slice"
import moment from "moment"
import { Segmented } from "antd-v5"
import { useAbac } from "react-abac"
import { MdFilterAlt, MdLocationOn, MdPerson } from "react-icons/md"
import { Icon } from "components"
import Planner from "./RotaPlanner/RotaPlanner"
import { FeatureFlagEnums } from "types/featureFlags.type"
import useFeatureFlags from "hooks/useFeatureFlags"

const Index: React.FC = () => {
	const dispatch = useAppDispatch()
	const { userHasPermissions } = useAbac()
	const { featureFlagEnabled } = useFeatureFlags()
	const [time, setTime] = useState<moment.Moment>(moment())
	const [value, setValue] = useState<string>("location")
	useEffect(() => {
		dispatch(getBranches(false))
		dispatch(getSDSuppliers(false))
	}, [])

	useEffect((): any => {
		socket.emit(ROOM_EVENTS.JOIN_ROOM, SOCKET_ROOMS.SCHEDULES)

		socket.on(SCHEDULE_SOCKET_EVENTS.ADDED, (res: any) => {
			dispatch(scheduleSaved(res))
		})

		socket.on(SCHEDULE_SOCKET_EVENTS.UPDATED, res => {
			dispatch(scheduleSaved(res))
		})

		return () => socket.emit(ROOM_EVENTS.LEAVE_ROOM, SOCKET_ROOMS.SCHEDULES)
	}, [])

	const options = useMemo(() => {
		return [
			{
				label: "By Site",
				value: "location",
				icon: (
					<span className="anticon">
						<MdLocationOn className="mb-[5px]" size={18} />
					</span>
				),
				perform: Permissions.VIEW_SCHEDULE_BY_LOCATION,
				featureName: FeatureFlagEnums.SCHEDULES_BY_LOCATION,
				component: <Location parentDate={time} setParentDate={setTime} />,
			},
			{
				label: "By Employee",
				value: "employee",
				icon: (
					<span className="anticon">
						<MdPerson className="mb-[5px]" size={18} />
					</span>
				),
				perform: Permissions.VIEW_SCHEDULE_BY_EMPLOYEE,
				featureName: FeatureFlagEnums.SCHEDULES_BY_EMPLOYEE,
				component: <Employee parentDate={time} setParentDate={setTime} />,
			},
			{
				label: "By Filter",
				value: "filter",
				icon: (
					<span className="anticon">
						<MdFilterAlt className="mb-[5px]" size={18} />
					</span>
				),
				perform: Permissions.VIEW_SCHEDULE_BY_FILTER,
				featureName: FeatureFlagEnums.SCHEDULES_BY_FILTER,
				component: <SchedulesFilter />,
			},
			{
				label: "Planner",
				value: "planner",
				icon: (
					<span className="anticon">
						<Icon name="schedules" color="dark" className="mb-[5px]" size={18} />
					</span>
				),
				perform: Permissions.VIEW_SCHEDULE_BY_FILTER,
				featureName: FeatureFlagEnums.SCHEDULES_BY_FILTER, // TODO: Add planer feature flag enum
				component: <Planner />,
			},
		].filter(item => userHasPermissions(item.perform) && featureFlagEnabled(item.featureName))
	}, [time, setTime])
	return (
		<div className="relative">
			<div className="absolute top-2 right-5 z-10">
				<Segmented prefixCls="ant5" options={options} value={value} onChange={(e: any) => setValue(e)} />
			</div>
			<div className="min-h-full flex-1">{options.find(item => item.value === value)?.component}</div>
		</div>
	)
}

export default Index
