import WorkMonitorView from "pages/workMonitor/WorkMonitorView"
import { ReportShiftInterface } from "@type/workMonitor.types"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import EmployeeInfo from "./EmployeeInfo"
import LocationInfo from "./LocationInfo"
import { CSVLink } from "react-csv"
import { DATE_FORMATS, ShiftStatus } from "config/constants"
import { useReactToPrint } from "react-to-print"
import { convertIOSDateToHTMLFormat, sortByProperty } from "config/utils"
import logo from "../../../images/logo1.jpg"
import moment from "moment"
import IncidentView from "./IncidentInfo"
import { pageStyle } from "./constants"
import { Card, Divider, Radio } from "antd-v5"
import { useHeightResize } from "hooks"
import { AntdButton } from "components"
import { CloudDownloadOutlined } from "@ant-design/icons"
import ShiftEventsRetainContext, { ShiftEventsContextProps } from "./ShiftEventsContext"
import { GetScheduleFromTableInterface } from "@type/schedules.types"

interface Props {
	data: GetScheduleFromTableInterface
}
const headers = [
	{ label: "Officer Name", key: "employee" },
	{ label: "Site Name", key: "location" },
	{ label: "Date", key: "date" },
	{ label: "Event Type", key: "status" },
	{ label: "Event Time", key: "eventTime" },
	{ label: "QR Name", key: "name" },
	{ label: "Location", key: "latLng" },
	{ label: "Address", key: "" },
	{ label: "Comments", key: "comment" },
]

enum ScheduleInfoTabs {
	"Employee" = "Employee",
	"SITE" = "SITE",
	"Activity" = "Activity",
}

const radioItems = Object.keys(ScheduleInfoTabs)

const ScheduleInfo: React.FC<Props> = ({ data: { _id: scheduleId, employee: employeeId, location: locationId } }) => {
	useHeightResize({ element: "schedule-info-body" })

	const { shift, fetchShiftByScheduleId } = useContext(ShiftEventsRetainContext) as ShiftEventsContextProps
	const componentRef = useRef(null)
	const [activeRadio, setActiveRadio] = useState(radioItems[0])

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle,
	})

	useEffect(() => {
		fetchShiftByScheduleId(scheduleId)
	}, [scheduleId])

	const shiftEventsData = useMemo(() => {
		const data: any[] = []
		if (!shift?.shiftEvents) return []
		shift?.shiftEvents
			.filter(item => !item.isEventMissed)
			.forEach(({ createdAt, geo, eventTime, status, ...shiftEvent }) => {
				if (status === "QR") {
					shiftEvent.scannedQREvents?.forEach(
						({
							createdAt: createdAtQR,
							eventTime: eventTimeQR,
							geo: geoQR,
							status: statusQR,
							name,
							...shiftEvent
						}) => {
							data.push({
								...shiftEvent,
								employee: shift.employee,
								eventTime: convertIOSDateToHTMLFormat(
									eventTimeQR || "",
									DATE_FORMATS.REPORT_DD_MM_YYYY_HH_mm,
								),
								date: convertIOSDateToHTMLFormat(createdAtQR, DATE_FORMATS.REPORT_DD_MM_YYYY),
								latLng: `${geoQR?.coordinates[0].toFixed(4) || ""} , ${
									geoQR?.coordinates[1].toFixed(4) || ""
								}`,
								location: shift?.location,
								status: statusQR,
								name,
							})
						},
					)
				} else {
					data.push({
						...shiftEvent,
						eventTime: convertIOSDateToHTMLFormat(eventTime || "", DATE_FORMATS.REPORT_DD_MM_YYYY_HH_mm),
						employee: shift.employee,
						date: convertIOSDateToHTMLFormat(createdAt || "", DATE_FORMATS.REPORT_DD_MM_YYYY),
						latLng: geo?.coordinates.length
							? `${geo?.coordinates[0].toFixed(4) || ""} , ${geo?.coordinates[1].toFixed(4) || ""}`
							: "",
						location: shift?.location || "",
						status,
					})
				}
			})
		return data.sort(sortByProperty("eventTime"))
	}, [shift, scheduleId])

	if (!employeeId && !locationId) return <></>
	return (
		<div className="flex flex-col space-y-4">
			<Radio.Group
				className="w-full"
				buttonStyle="outline"
				optionType="button"
				value={activeRadio}
				onChange={e => setActiveRadio(e.target.value)}
			>
				<div className="grid grid-cols-3">
					{radioItems.map(item => (
						<Radio
							key={item}
							className="text-center"
							disabled={item === ScheduleInfoTabs.Employee && !employeeId}
							value={item}
						>
							{item}
						</Radio>
					))}
				</div>
			</Radio.Group>
			<div id="schedule-info-body" className="overflow-y-auto">
				{activeRadio === ScheduleInfoTabs.Employee && <EmployeeInfo id={employeeId} />}
				{activeRadio === ScheduleInfoTabs.SITE && <LocationInfo id={locationId} />}
				{activeRadio === ScheduleInfoTabs.Activity && (
					<Card className="min-h-80" size="small">
						{!shift?.incidents?.length && !shift?.shiftEvents?.length && (
							<div className="min-h-full items-center text-center text-lg font-semibold">No Data</div>
						)}
						{!!shift?.incidents?.length && <IncidentView incidents={shift?.incidents ?? []} />}
						<Divider style={{ margin: "8px", marginLeft: "0px", marginRight: "0px" }} />
						{!!(shift?.shiftEvents?.filter(events => events.status !== ShiftStatus.CHASED) || [])
							.length && (
							<>
								<div className="flex flex-col items-center justify-center gap-2 2xl:flex-row">
									<CSVLink
										data={shiftEventsData}
										headers={headers}
										filename={`Report-${shift?.employee}-${shift?.post}`}
									>
										<AntdButton
											className="flex items-center justify-center"
											icon={<CloudDownloadOutlined />}
										>
											Generate CSV
										</AntdButton>
									</CSVLink>

									<AntdButton
										className="flex items-center justify-center"
										icon={<CloudDownloadOutlined />}
										onClick={handlePrint}
									>
										Generate PDF
									</AntdButton>
								</div>

								<WorkMonitorView shift={shift} containerClass="h-auto mt-2 border-none" />
							</>
						)}
					</Card>
				)}
				<ShiftEventPrint
					data={shiftEventsData}
					time={{ startTime: shift?.startTime, finishTime: shift?.finishTime }}
				/>
			</div>
		</div>
	)
}

interface ShiftEventProps {
	data: ReportShiftInterface[]
	time: { startTime?: string; finishTime?: string }
}

const ShiftEventPrint: React.FC<ShiftEventProps> = ({ data, time }) => {
	const Headers = {
		status: "Status",
		latLng: "Coordinates",
		eventTime: "Event Time",
		dueTime: "Due Time",
		name: "QR Tag",
	}
	return (
		<>
			<div className="grid grid-cols-8">
				<div id="title" className="col-span-2 hidden text-left">
					{data[1]?.employee}
					<br />
					{moment(time.startTime).format(DATE_FORMATS.REPORT_DD_MM_YYYY_HH_mm) +
						" to " +
						moment(time.finishTime).format(DATE_FORMATS.HH_mm)}
				</div>

				<div id="reportName" className="col-span-4 hidden text-center text-md">
					Shift Event Report for {data[1]?.location}
				</div>

				<img
					id="logo"
					src={logo}
					alt="TimeTrail-Login"
					className="col-span-2 hidden h-20  w-auto object-cover"
				/>
			</div>
			<div id="parent" className="hidden  w-full flex-col justify-center">
				{[Headers, ...data]
					.filter(event => event.status !== "CHASED")
					.sort((a, b) => new Date(a.dueTime).valueOf() - new Date(b.dueTime).valueOf())
					.map((entity, index) => {
						return (
							<div
								id="row"
								key={index}
								className="grid grid-cols-12 space-x-2 border-b border-b-gray-300 py-0.25"
							>
								<div className="col-span-1">{entity.status}</div>
								<div className="col-span-3">{entity.latLng ?? "N/A"}</div>
								<div className="col-span-3">{entity.eventTime}</div>
								<div className="col-span-2">
									{moment(entity.dueTime).isValid()
										? moment(entity.dueTime).format("HH:mm")
										: entity.dueTime}
								</div>
								<div className="col-span-3">{entity.name ?? "N/A"}</div>
							</div>
						)
					})}
			</div>
		</>
	)
}

export default ScheduleInfo
