import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetAuditTrailInterFace, StateAuditTrailInterFace, Entities, EntitiesType } from "types/auditTrail.types"
import { auditTrailService } from "services"
import { AppThunk, RootState } from "store"

export interface AuditTrailStateInterface {
	object: { [key in Entities]: { [key: string]: StateAuditTrailInterFace[] } }
	loaders: { [key in Entities]: { [key: string]: boolean } }
}

const initialState: AuditTrailStateInterface = {
	object: Object.fromEntries(
		Object.keys(Entities).map((item: string) => [Entities[item as keyof EntitiesType] as EntitiesType, {}]),
	),
	loaders: Object.fromEntries(
		Object.keys(Entities).map((item: string) => [Entities[item as keyof EntitiesType] as EntitiesType, {}]),
	),
}

const auditTrailSlice = createSlice({
	name: "auditTrail",
	initialState,
	reducers: {
		fetchingAuditTrail: (auditTrail, action: PayloadAction<{ id: string; entityName: Entities }>) => {
			const {
				payload: { entityName, id },
			} = action
			if (!auditTrail.loaders[entityName]) auditTrail.loaders[entityName] = {}
			auditTrail.loaders[entityName][id] = true
		},
		auditTrailFetched: (
			auditTrail,
			action: PayloadAction<{ data: GetAuditTrailInterFace[]; entityName: Entities }>,
		) => {
			const {
				payload: { entityName, data = [] },
			} = action

			if (!auditTrail.loaders[entityName]) auditTrail.loaders[entityName] = {}
			auditTrail.loaders[entityName][data[0].documentNumber] = false

			if (!auditTrail.object[entityName]) auditTrail.object[entityName] = {}
			auditTrail.object[entityName][data[0].documentNumber] = data
				.map((item, index) => ({
					...data[index],
					user: `${item.user.firstName} ${item.user.lastName}`,
				}))
				.sort((a, b) => (new Date(a["createdAt"]) > new Date(b["createdAt"]) ? -1 : 1))
		},

		auditTrailFetchingFailed: (auditTrail, action: PayloadAction<{ id: string; entityName: Entities }>) => {
			const {
				payload: { entityName, id },
			} = action
			if (!auditTrail.loaders[entityName]) auditTrail.loaders[entityName] = {}
			auditTrail.loaders[entityName][id] = false
		},
	},
})

//REDUCER>
export default auditTrailSlice.reducer

//ACTIONS
const { fetchingAuditTrail, auditTrailFetched, auditTrailFetchingFailed } = auditTrailSlice.actions

const getAuditTrail =
	(entityId: string, entityName: Entities): AppThunk =>
	async dispatch => {
		try {
			dispatch(fetchingAuditTrail({ id: entityId, entityName }))
			const { data: auditTrailResponse } = await auditTrailService.get(entityId, entityName)
			dispatch(auditTrailFetched({ data: auditTrailResponse, entityName }))
		} catch (error) {
			dispatch(auditTrailFetchingFailed({ id: entityId, entityName }))
		}
	}

export { getAuditTrail }

//SELECTORS
const selectAuditTrailState = (state: RootState) => state.auditTrail

const selectAuditTrail = (id: string, entityName: Entities) => (state: RootState) =>
	selectAuditTrailState(state).object?.[entityName]?.[id] || []

const isAuditTrailLoading = (id: string, entityName: Entities) => (state: RootState) =>
	selectAuditTrailState(state).loaders?.[entityName]?.[id] || false

export { selectAuditTrailState, isAuditTrailLoading, selectAuditTrail }
