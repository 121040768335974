import { yupResolver } from "@hookform/resolvers/yup"
import { useAppDispatch, useAppSelector } from "hooks"
import {
	duplicateLocationContract,
	isLocationContractsSaving,
	selectLocationContractsDataDropDown,
} from "store/Location/locationContracts.slice"
import { selectSelectedLocation } from "store/globalState.slice"
import { duplicateContractInitialValues, duplicateContractSchema } from "Schema/Locations/locationSchema"
import { Modal, DateField, FormSelectAntd, FormCheckbox, AntdButton } from "components"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
}

export interface DuplicateContractInterface {
	duplicateFrom: string
	startDate: string
	finishDate?: string
	duplicateTimeCaptures: boolean
	location?: string
}

const DuplicateContractModal: React.FC<Props> = ({ open, modalHandler }) => {
	const dispatch = useAppDispatch()
	const selected = useAppSelector(selectSelectedLocation)
	const contractsList = useAppSelector(selectLocationContractsDataDropDown(selected))
	const isSaving = useAppSelector(isLocationContractsSaving())
	const methods = useForm<DuplicateContractInterface>({
		resolver: yupResolver(duplicateContractSchema),
	})

	const {
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { isDirty, errors, isSubmitting },
	} = methods

	useEffect(() => {
		reset(duplicateContractInitialValues)
	}, [open])

	const onSubmit = (payload: DuplicateContractInterface) => {
		const { finishDate, ...rest } = payload
		dispatch(
			duplicateLocationContract({ location: selected, ...(!!finishDate && { finishDate }), ...rest }, () =>
				modalHandler(false),
			),
		)
	}

	return (
		<Modal open={open} modalHandler={modalHandler} containerClass="min-w-[500px]">
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="space-y-6">
						<div className="grid grid-cols-2 gap-2">
							<FormSelectAntd
								containerClass="col-span-2 w-1/2"
								label="Duplicate from"
								name="duplicateFrom"
								options={contractsList}
							/>
							<DateField
								label="Contract Start Date"
								setValue={setValue}
								errors={errors}
								watch={watch}
								name="startDate"
							/>
							<DateField
								label="Contract Finish Date"
								setValue={setValue}
								errors={errors}
								watch={watch}
								name="finishDate"
							/>
							<FormCheckbox type="checkbox" label="Duplicate TimeCaptures" name="duplicateTimeCaptures" />
						</div>
						<div className="flex justify-end space-x-2">
							<AntdButton onClick={() => modalHandler(false)} htmlType="button">
								Cancel
							</AntdButton>
							<AntdButton
								disabled={!isDirty || isSubmitting || isSaving}
								loading={isSaving}
								htmlType="submit"
							>
								Submit
							</AntdButton>
						</div>
					</div>
				</form>
			</FormProvider>
		</Modal>
	)
}

export default DuplicateContractModal
