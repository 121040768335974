import React, { useState } from "react"
import useAppDispatch from "hooks/useAppDispatch"
import useAppSelector from "hooks/useAppSelector"
import { AntdButton, Modal } from "components"
import ConfirmationModalBody from "./ConfirmationModalBody"
import { axiosInstanceGlobal } from "config/api"
import {
	clearConfirmationModalData,
	handleConfirmationResponse,
	selectConfirmationResponseData,
} from "store/globalState.slice"

const GlobalConfirmationModal = () => {
	const dispatch = useAppDispatch()
	const confirmationResponse = useAppSelector(selectConfirmationResponseData)
	const [loading, setLoading] = useState(false)
	const handleOk = async () => {
		setLoading(true)
		if (confirmationResponse) {
			const { request, confirmation } = confirmationResponse

			const response = await axiosInstanceGlobal({
				method: request?.method,
				url: request.url,
				data: {
					...request.body,
					flags: {
						...confirmation.flags,
						...Object.fromEntries(confirmation.targetFlags.map((item: string) => [item, true])),
					},
				},
			})
			if (response?.data?.confirmation) {
				handleConfirmationResponse(response, confirmationResponse.targetReducer)
			} else {
				dispatch({ type: confirmationResponse.targetReducer, payload: response.data })
				dispatch(clearConfirmationModalData())
			}
		}
		setLoading(false)
	}
	const handleCancel = () => {
		dispatch(clearConfirmationModalData())
		setLoading(false)
	}

	return (
		<Modal
			containerClass="!max-w-[90%]"
			open={!!confirmationResponse}
			title={"Caution!"}
			modalHandler={handleCancel}
		>
			<div>
				<ConfirmationModalBody views={confirmationResponse?.confirmation.views} />
				<div className="item-center flex w-full justify-end space-x-2">
					<AntdButton onClick={handleCancel}>Cancel</AntdButton>
					<AntdButton className="flex items-center justify-center" onClick={handleOk} loading={loading}>
						Confirm
					</AntdButton>
				</div>
			</div>
		</Modal>
	)
}

export default GlobalConfirmationModal
