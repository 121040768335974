import { combineReducers } from "@reduxjs/toolkit"
import SDQualifications from "./SDQualifications.slice"
import SDEquipmentTypes from "./SDEquipmentTypes.slice"
import SDDocumentTypes from "./SDDocumentTypes.slice"
import SDCounties from "./SDCounties.slice"
import SDSuppliers from "./SDSuppliers.slice"
import SDIncidents from "./SDIncidents.slice"
import SDCompanies from "./SDCompanies.slice"
import SDEventTypes from "./SDEventTypes.slice"

const OrganizationReducer = combineReducers({
	SDQualifications,
	SDDocumentTypes,
	SDEquipmentTypes,
	SDCounties,
	SDSuppliers,
	SDIncidents,
	SDCompanies,
	SDEventTypes,
})

export default OrganizationReducer
