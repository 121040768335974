import { EmployeeTypesEnum, ScheduleTypesEnum } from "config/constants"
import { ScheduleStatus, SchedulesFilter } from "../Constants/schedulesConstants"
import { Icon } from "components"
import { twMerge } from "tailwind-merge"
import cx from "classnames"
import { useMemo } from "react"
import { ColorName, IconName } from "components/types"

interface Props {
	isDeleted: boolean
	name: string
	time: string
	employeeType: EmployeeTypesEnum
	status: ScheduleStatus
	postName?: string
	scheduleType: ScheduleTypesEnum
}

const borderColorMap: {
	[key: string]: {
		border: string
		background: string
		icon: IconName
		iconContainer: string
	}
} = {
	[ScheduleStatus.ACCEPTED]: {
		border: "ring-green-600",
		background: "bg-green-600/10",
		icon: "success",
		iconContainer: "bg-green-600",
	},
	[ScheduleStatus.INACTIVE]: {
		border: "ring-yellow-600",
		background: "bg-yellow-500/10",
		icon: "inactive",
		iconContainer: "bg-yellow-500",
	},
	[ScheduleStatus.PENDING]: {
		border: "ring-orange-600",
		background: "bg-orange-200/30",
		icon: "pending",
		iconContainer: "bg-[#F27410]",
	},
	[ScheduleStatus.REJECTED]: {
		border: "ring-red-600",
		background: "bg-red-600/10",
		icon: "rejected",
		iconContainer: "bg-red-600",
	},
	[SchedulesFilter.UNALLOCATED]: {
		border: "ring-orange-600",
		background: "bg-red-900/20",
		icon: "unallocated",
		iconContainer: "bg-[#800000]",
	},
}

const ScheduleTableCell: React.FC<Props> = ({
	employeeType,
	isDeleted,
	name,
	scheduleType,
	status,
	time,
	postName = "",
}) => {
	const colors =
		borderColorMap[
			name === SchedulesFilter.UNALLOCATED && status !== ScheduleStatus.INACTIVE
				? SchedulesFilter.UNALLOCATED
				: status
		]
	const iconProps = useMemo(() => {
		return employeeType === EmployeeTypesEnum.SECURITY
			? { name: "security" as IconName, color: "info" as ColorName }
			: { name: "cleaning" as IconName, color: "tangerine" as ColorName }
	}, [employeeType])

	return (
		<div
			className={twMerge(
				cx({
					[`grid h-full min-w-full select-none grid-cols-6 gap-x-0.5 rounded-sm border px-[2px] py-1 text-xs font-normal text-black ring-1 transition duration-300 hover:bg-white 2xl:text-sm 3xl:gap-x-1 3xl:px-1 ${colors.background} ${colors.border}`]:
						true,
					"font-bold text-[#800000]": name === SchedulesFilter.UNALLOCATED,
					"text-red-500 line-through": isDeleted,
				}),
			)}
		>
			<div className="col-span-5 flex flex-col justify-between space-y-1">
				<div className="line-clamp-2 flex min-h-[30px] flex-col items-end justify-end leading-[15px] tracking-tighter 3xl:tracking-normal">
					{name}
				</div>
				{postName && <div className="font-medium  text-slate-600">{postName}</div>}
				{scheduleType === ScheduleTypesEnum.DUTY && <div className="font-medium  text-slate-900">{time}</div>}
			</div>
			<div className="col-span-1 flex w-full flex-col items-end justify-between">
				<div
					className={twMerge(
						`${colors.iconContainer} flex h-[16px] w-[16px] items-center justify-center rounded-full`,
					)}
				>
					<Icon name={isDeleted ? "delete" : colors.icon} color={isDeleted ? "danger" : "white"} size={12} />
				</div>
				{employeeType ? (
					<div className="items-center justify-center  ">
						<Icon {...iconProps} size={16} />
					</div>
				) : (
					<></>
				)}
			</div>
		</div>
	)
}

export default ScheduleTableCell
