import * as Yup from "yup"

export const workMonitorShiftUpdateModalInitialValues = {
	comment: "",
	thumbnail: "",
}

export const updateShiftModalSchema = Yup.object().shape({
	comment: Yup.string().required().label("comment"),
	thumbnail: Yup.array().label("thumbnail"),
})

export const incidentReportModalInitialValues = {
	incident: "",
	officerName: "",
	siteName: "",
	date: "",
	policeRequested: false,
	reference: "",
	actionTaken: false,
	controlInformed: false,
	details: "",
	reportingOfficer: "",
	images: [],
}

export const incidentReportSchema = Yup.object().shape({
	incident: Yup.string().required().label("Incident"),
	type: Yup.string().required().label("Type"),
	officerName: Yup.string().required().label("Security Officer Name"),
	siteName: Yup.string().required().label("Site Name"),
	date: Yup.string().required().label("Date and Time of Incident"),
	policeRequested: Yup.boolean().required().label("Police Requested"),
	reference: Yup.string().when("policeRequested", { is: true, then: Yup.string().required().label("Reference") }),
	actionTaken: Yup.boolean().required().label("Action Taken"),
	controlInformed: Yup.boolean().required().label("Control Informed"),
	details: Yup.string().required().label("Details of Incident"),
	reportingOfficer: Yup.string().required().label("Reporting Officer Name"),
	images: Yup.array().label("images"),
})
