import { IncidentReportInterface, ShiftInterface } from "@type/workMonitor.types"
import {
	Modal,
	FormSwitchInput,
	DateField,
	ImageField,
	Icon,
	FormInputAntd,
	FormSelectAntd,
	AntdButton,
} from "components"
import { FormProvider, useForm } from "react-hook-form"
import React, { useContext, useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { incidentReportModalInitialValues, incidentReportSchema } from "Schema/workMonitor/workMonitorSchema"
import useAppDispatch from "hooks/useAppDispatch"
import { addIncidentInShift } from "store/workMonitor/workMonitor.slice"
import useAppSelector from "hooks/useAppSelector"
import { clearMedia, selectLoadingMedia, selectMediaURL, uploadMedia } from "store/Media/media.slice"
import { selectCurrentUser } from "store/auth.slice"
import { SDIncidentsOptions } from "store/StaticData/SDIncidents.slice"
import { LocationIncidentInterface } from "@type/locations.types"
import { locationIncidentSchema } from "Schema/Locations/locationSchema"
import ShiftEventsRetainContext, { ShiftEventsContextProps } from "pages/schedules/Location/ShiftEventsContext"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	selectedRow: ShiftInterface | null
	loading?: boolean
	isForLocation?: boolean
	defaultFields?: LocationIncidentInterface
	submitHandler?: (data: any) => void
}
const IncidentModal: React.FC<Props> = ({
	open,
	modalHandler,
	selectedRow,
	loading = false,
	isForLocation = false,
	defaultFields = undefined,
	submitHandler = undefined,
}) => {
	const methods = useForm<IncidentReportInterface>({
		resolver: yupResolver(isForLocation ? locationIncidentSchema : incidentReportSchema),
		defaultValues: incidentReportModalInitialValues,
	})
	const {
		handleSubmit,
		reset,
		setValue,
		setError,
		clearErrors,
		watch,
		formState: { errors, isSubmitSuccessful },
	} = methods
	const policeRequestedIsEnabled = watch("policeRequested")
	const dispatch = useAppDispatch()
	const [userImage, setUserImage] = useState<string[]>([])
	const imageURL = useAppSelector(selectMediaURL())
	const isImageLoading = useAppSelector(selectLoadingMedia())
	const user = useAppSelector(selectCurrentUser())
	const SDIncidentsDropdownOptions = useAppSelector(SDIncidentsOptions)
	const { fetchShiftByScheduleId } = useContext(ShiftEventsRetainContext) as ShiftEventsContextProps

	useEffect(() => {
		if (isForLocation) {
			reset(defaultFields ?? { images: [], policeRequested: false, controlInformed: false, actionTaken: false })
			setUserImage([])
		}
		if (selectedRow) {
			reset(incidentReportModalInitialValues)
			setValue("officerName", selectedRow.employee)
			setValue("siteName", selectedRow.location)
			setUserImage([])
		}
		if (user) {
			setValue("reportingOfficer", `${user?.firstName} ${user?.lastName}`)
		}
	}, [selectedRow, isSubmitSuccessful, user, defaultFields])

	useEffect(() => {
		if (imageURL.length) {
			setUserImage(prevState => [...prevState, ...imageURL])
			dispatch(clearMedia())
		}
	}, [imageURL])

	const onSubmit = (data: IncidentReportInterface) => {
		const payload = {
			...data,
			reportingOfficer: user?._id || "",
			images: [...data.images, ...userImage],
			employeeId: selectedRow?.employeeId,
			locationId: selectedRow?.locationId,
		}

		if (isForLocation && submitHandler) submitHandler(payload)
		else if (selectedRow?._id)
			dispatch(
				addIncidentInShift(selectedRow?._id, payload, () => fetchShiftByScheduleId(selectedRow.scheduleId)),
			)

		modalHandler(false)
	}

	const handleDeleteImage = (imageUrl: string) => {
		setUserImage(prevState => prevState.filter(url => url !== imageUrl))
	}

	return (
		<Modal
			containerClass="max-w-3xl"
			open={open}
			title={
				<div className="py-3 text-center text-md font-bold uppercase tracking-wider text-dark">
					Report Incident
				</div>
			}
			modalHandler={modalHandler}
		>
			<div>
				{loading ? (
					<div className="flex h-full w-full items-center justify-center">
						<Icon name="spinner" color="white" size={50} className="mr-3 animate-spin !text-purple" />
					</div>
				) : (
					<FormProvider {...methods}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="grid grid-cols-2 gap-x-4 gap-y-5 py-3">
								{!isForLocation && (
									<>
										<FormInputAntd label="Site Name" type="text" name="siteName" />
										<FormInputAntd label="Security Officer Name" type="text" name="officerName" />
									</>
								)}
								<DateField
									label="Date and Time of Incident"
									name="date"
									errors={errors}
									clearErrors={clearErrors}
									setValue={setValue}
									watch={watch}
									showTime={true}
								/>
								<FormInputAntd
									label="Reporting Officer Name"
									type="text"
									name="reportingOfficer"
									disabled={true} // doing this temporarily
								/>
							</div>
							<div className="col-span-2 flex transform items-center space-x-2">
								<div className="min-w-fit font-semibold">Incident Details</div>
								<div className="w-full border-b-[0.5px] border-secondary-light" />
							</div>
							<div className="grid grid-cols-2 gap-x-4 py-3 ">
								<FormInputAntd label="Incident" name="incident" type="text" />
								<FormSelectAntd
									disabled={!SDIncidentsDropdownOptions.length}
									label="Type"
									name="type"
									options={SDIncidentsDropdownOptions}
								/>
							</div>
							<div className="grid grid-cols-3 gap-x-4 py-3 ">
								<FormSwitchInput
									containerClass="flex items-center w-60"
									label="Action Taken"
									name="actionTaken"
									watch={watch}
									errors={errors}
									setValue={setValue}
								/>
								<FormSwitchInput
									containerClass="flex items-center w-60"
									label="Control Informed"
									name="controlInformed"
									watch={watch}
									errors={errors}
									setValue={setValue}
								/>
								<FormSwitchInput
									containerClass="flex items-center w-60"
									label="Police Requested"
									name="policeRequested"
									watch={watch}
									errors={errors}
									setValue={setValue}
								/>
								<div className="col-span-3 mt-3 grid grid-cols-3 gap-x-5">
									<div className={"col-span-2"}>
										<FormInputAntd type="textarea" label="Details of Incident" name="details" />
									</div>
									<FormInputAntd
										disabled={!policeRequestedIsEnabled}
										label="Reference"
										name="reference"
										type="text"
									/>
								</div>
							</div>
							<div className="flex items-center">
								<ImageField
									name="images"
									containerClass="h-16 w-32"
									loading={isImageLoading}
									image={userImage}
									setError={setError}
									errors={errors}
									uploadMultiple={true}
									clearErrors={clearErrors}
									onImageChange={file => {
										if (!errors["images"]) {
											dispatch(uploadMedia(file))
										}
									}}
									handleDeleteImage={handleDeleteImage}
								/>
							</div>
							<div className="flex h-fit w-full justify-end">
								<AntdButton htmlType="submit">Report Incident</AntdButton>
							</div>
						</form>
					</FormProvider>
				)}
			</div>
		</Modal>
	)
}

export default IncidentModal
