import { useMemo } from "react"
import { useAppSelector, useAppDispatch } from "hooks"
import { AntdButton, SearchField } from "components"
import { selectWorkMonitorSaving, updateMultipleShifts } from "store/workMonitor/workMonitor.slice"
import { ShiftInterface } from "@type/workMonitor.types"
import { WorkMonitorShiftsClassified } from "@store/workMonitor/workMonitorManager"
import { MdFilterAlt } from "react-icons/md"
interface Props {
	selectedRow: ShiftInterface[] | null
	resetShiftState: () => void
	multipleEventsData: any
	setShiftData: (val: string) => void
	searchString: string
	setSearchString: (val: string) => void
	setShiftStatusChangeModalOpen: (val: boolean) => void
	setOpenAllocateModal: (val: boolean) => void
	setOpenDeleteModal: (val: boolean) => void
	setOpenShiftFilterDrawer: (val: boolean) => void
	setOpenShiftDetailsDrawer: (val: boolean) => void
	setOpenIncidentModal: (val: boolean) => void
	shifts: WorkMonitorShiftsClassified
	searchModal: boolean
	setSearchModal: (val: boolean) => void
}

const WorkMonitorActions: React.FC<Props> = ({
	selectedRow,
	resetShiftState,
	multipleEventsData,
	setShiftData,
	searchString,
	setSearchString,
	setShiftStatusChangeModalOpen,
	setOpenAllocateModal,
	setOpenDeleteModal,
	setOpenShiftFilterDrawer,
	setOpenIncidentModal,
	setOpenShiftDetailsDrawer,
	shifts,
	searchModal,
	setSearchModal,
}) => {
	// const shifts = useAppSelector(selectWorkMonitorDataStructureChanged())
	const workMonitorSaving = useAppSelector(selectWorkMonitorSaving())
	const dispatch = useAppDispatch()

	const onStatusHandler = () => {
		// there might be problem here check here
		if (selectedRow && selectedRow?.length > 1) {
			dispatch(updateMultipleShifts(multipleEventsData, resetShiftState))
		} else {
			setShiftData(selectedRow?.[0]?._id || "")
			setShiftStatusChangeModalOpen(true)
		}
	}

	const selectedShift = useMemo(() => {
		return (
			shifts?.allocatedShift?.find(item => item._id === selectedRow?.[0]?._id) ||
			shifts?.activeShift?.find(item => item._id === selectedRow?.[0]?._id) ||
			shifts?.criticalShift?.find(item => item._id === selectedRow?.[0]?._id)
		)
	}, [shifts, selectedRow])

	return (
		<div className="flex justify-between bg-white">
			<span className="flex w-auto gap-2 py-2.5 px-4">
				<AntdButton
					className="rounded-md text-[14px]"
					disabled={
						!selectedRow ||
						!selectedShift?.employee ||
						selectedRow.some(row => !row.employee) ||
						workMonitorSaving
					}
					loading={workMonitorSaving}
					onClick={onStatusHandler}
				>
					Take
				</AntdButton>
				<AntdButton
					className="rounded-md text-[14px]"
					disabled={!selectedRow || selectedRow?.length > 1}
					onClick={() => setOpenAllocateModal(true)}
				>
					Allocate
				</AntdButton>
				<AntdButton
					className="rounded-md text-[14px]"
					disabled={!selectedRow || selectedRow?.length > 1}
					onClick={() => setOpenDeleteModal(true)}
				>
					Delete
				</AntdButton>
				<AntdButton
					className="rounded-md text-[14px]"
					disabled={!selectedRow || selectedRow?.length > 1}
					onClick={() => setOpenShiftDetailsDrawer(true)}
				>
					View Events
				</AntdButton>
				<AntdButton
					className="rounded-md text-[14px]"
					disabled={!selectedRow || selectedRow?.length > 1}
					onClick={() => setOpenIncidentModal(true)}
				>
					Incident
				</AntdButton>
				<AntdButton
					className="ml-5 cursor-pointer"
					icon={
						<span className="anticon">
							<MdFilterAlt className="mb-[5px]" size={18} style={{ color: "rgb(156 163 175)" }} />
						</span>
					}
					onClick={() => setOpenShiftFilterDrawer(true)}
				>
					Filter Shifts
				</AntdButton>
			</span>
			<div>
				<span className="flex items-end justify-end space-x-4 py-2.5 px-6">
					<SearchField
						placeholder="Search...."
						setSearchString={setSearchString}
						searchString={searchString}
						className="h-10 !w-full !py-0 !pr-5 text-sm"
					/>
					<AntdButton onClick={() => setSearchModal(!searchModal)}>Global Search</AntdButton>
				</span>
			</div>
		</div>
	)
}

export default WorkMonitorActions
