import { updateState } from "config/utils"
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GetPostsInterface, CreatePostsInterface, InActiveSchedule } from "@type/locations.types"
import { locationPosts } from "services"
import { AppThunk, RootState } from "store"
import LocationSchedules from "services/schedules/schedules.service"
import { fetchInactiveSchedulesByEmployee, inactiveScheduleByEmployeeSaved } from "store/Schedules/schedules.slice"
import { orderBy } from "lodash"
import RotaPlannerService from "services/rotaPlanner/rotaPlanner.service"

export interface locationPostsStateInterface {
	list: GetPostsInterface[]
	loading: boolean
	saving: boolean
}

const initialState: locationPostsStateInterface = {
	list: [],
	loading: false,
	saving: false,
}

const locationPostsSlice = createSlice({
	name: "locationPosts",
	initialState,
	reducers: {
		fetchingLocationPosts: location => {
			location.loading = true
		},
		clearPostList: location => {
			location.list = []
		},
		locationPostsFetched: (location, action: PayloadAction<GetPostsInterface[]>) => {
			location.loading = false
			location.list = action.payload
		},
		locationPostsFetchingFailed: location => {
			location.loading = false
		},
		savingLocationPosts: locationPostsData => {
			locationPostsData.saving = true
		},
		locationPostsSaved: (location, action: PayloadAction<GetPostsInterface>) => {
			location.saving = false
			const [UpdatedValue, NewAdded] = updateState(location.list, action.payload, "_id")
			if (NewAdded) {
				location.list = UpdatedValue
			}
		},
		inactiveScheduleSaved: (location, action: PayloadAction<GetPostsInterface[]>) => {
			location.saving = false
			const [updatedState] = updateState(location.list, action.payload, "_id")
			location.list = updatedState
		},
		locationPostsSavingFailed: location => {
			location.saving = false
		},
		clearLocationPosts: location => {
			location.list = []
		},
	},
})

//REDUCER
export default locationPostsSlice.reducer

//ACTIONS
export const {
	clearLocationPosts,
	fetchingLocationPosts,
	locationPostsFetched,
	locationPostsFetchingFailed,
	savingLocationPosts,
	locationPostsSaved,
	inactiveScheduleSaved,
	clearPostList,
	locationPostsSavingFailed,
} = locationPostsSlice.actions

const getLocationPostsByContractId =
	(contractId: string): AppThunk =>
	async dispatch => {
		try {
			if (contractId) {
				dispatch(clearLocationPosts())
				dispatch(fetchingLocationPosts())
				const { data: locationPostsResponse } = await locationPosts.getLocationPostsByContractId(contractId)
				dispatch(locationPostsFetched(locationPostsResponse))
			} else dispatch(clearLocationPosts())
		} catch (error) {
			dispatch(locationPostsFetchingFailed())
		}
	}

const saveLocationPosts =
	(locationPostsData: Partial<GetPostsInterface>, cb: (id: string) => void): AppThunk =>
	async dispatch => {
		let data1 = null
		try {
			dispatch(savingLocationPosts())
			if (locationPostsData._id) {
				data1 = await locationPosts.updateLocationPosts(locationPostsData._id, locationPostsData)
			} else {
				data1 = await locationPosts.createLocationPosts(locationPostsData as CreatePostsInterface)
			}
			const { data: locationPostsResponse } = data1
			dispatch(locationPostsSaved(locationPostsResponse))
			cb(locationPostsResponse._id)
		} catch (error) {
			dispatch(locationPostsSavingFailed())
		}
	}

const createInactiveSchedule =
	(payload: { postId: string; inActiveSchedule: InActiveSchedule[] }, cb: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingLocationPosts())
			const response = await LocationSchedules.createInactiveSchedule(payload)
			const { data: locationPostsResponse } = response
			dispatch(inactiveScheduleSaved(locationPostsResponse?.data || locationPostsResponse))
			dispatch(fetchInactiveSchedulesByEmployee(locationPostsResponse?.data || locationPostsResponse))
			cb()
		} catch (error) {
			dispatch(locationPostsSavingFailed())
		}
	}

const deleteInactiveSchedule =
	(payload: { [key: string]: string[] }, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingLocationPosts())
			const response = await LocationSchedules.deleteInactiveSchedule(payload)
			const { data: locationPostsResponse } = response
			dispatch(inactiveScheduleSaved(locationPostsResponse.data))
			dispatch(inactiveScheduleByEmployeeSaved(locationPostsResponse.data))
			cb && cb()
		} catch (error) {
			dispatch(locationPostsSavingFailed())
		}
	}

const createInactiveSchedulesFromRotaLoad =
	(
		payload: { contract: string; location: string; startTime: string; finishTime: string },
		cb?: () => void,
	): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingLocationPosts())
			const { data: locationPostsResponse } = await RotaPlannerService.createInactiveSchedulesFromRotaLoad(
				payload,
			)
			dispatch(inactiveScheduleSaved(locationPostsResponse))
			cb?.()
		} catch (error) {
			dispatch(locationPostsSavingFailed())
		}
	}

const deleteInactiveSchedulePermanently =
	(contract: string, unloadFrom: string, cb?: () => void): AppThunk =>
	async dispatch => {
		try {
			dispatch(savingLocationPosts())
			const { data: locationPostsResponse } = await RotaPlannerService.deleteInactiveSchedulePermanently(
				contract,
				unloadFrom,
			)

			dispatch(inactiveScheduleSaved(locationPostsResponse))
			cb?.()
		} catch (error) {
			dispatch(locationPostsSavingFailed())
		}
	}

export {
	getLocationPostsByContractId,
	saveLocationPosts,
	createInactiveSchedule,
	deleteInactiveSchedule,
	createInactiveSchedulesFromRotaLoad,
	deleteInactiveSchedulePermanently,
}

//SELECTORS
const selectLocationPostsState = (state: RootState) => state.location.posts
const isLocationPostsLoading = () => (state: RootState) => selectLocationPostsState(state).loading
const isLocationPostsSaving = () => (state: RootState) => selectLocationPostsState(state).saving
const selectPostsById = (postId: string | null) => (state: RootState) =>
	selectLocationPostsData()(state)?.find(post => post._id === postId)
const selectPostsByContractId = (contractId: string | null) => (state: RootState) =>
	selectLocationPostsData()(state)?.find(post => post.contract === contractId)
const selectLocationPostsData = () => (state: RootState) => selectLocationPostsState(state).list

const selectLocationPostsDataDropDown = () => (state: RootState) =>
	[{ shortName: "Select Post", _id: "" }, ...(selectLocationPostsState(state).list || [])]?.map(
		({ _id, shortName }) => ({
			label: shortName,
			value: _id,
		}),
	) || []

const selectPostsByNameForSchedules = (contractId: string) =>
	createSelector(
		(state: RootState) => state.location.posts.list,
		postList =>
			postList
				? orderBy(
						postList.filter(post => post.contract == contractId),
						["type", "displayOrder", "name"],
						["desc", "asc", "asc"],
				  ).map(({ name: label, _id, type }: { name: string; _id: string; type: string }) => ({
						label,
						post: _id,
						type,
				  }))
				: [],
	)

export {
	selectPostsByNameForSchedules,
	selectPostsById,
	selectLocationPostsData,
	selectLocationPostsState,
	isLocationPostsSaving,
	isLocationPostsLoading,
	selectLocationPostsDataDropDown,
	selectPostsByContractId,
}
