import { DATE_FORMATS } from "config/constants"
import { DatePicker } from "antd"
import { AntdButton, Modal } from "components"
import moment, { Moment } from "moment"
import { useState } from "react"

interface Props {
	open: boolean
	modalHandler: (open: boolean) => void
	onConfirmation: (date: Moment) => void
	confirmationAction?: boolean
	loader?: boolean
}

const UnloadRotaModal: React.FC<Props> = ({
	open,
	loader = false,
	modalHandler,
	onConfirmation,
	confirmationAction = false,
}) => {
	const [selectedDate, setSelectedDate] = useState<Moment>(moment())

	const onClick = () => onConfirmation(selectedDate)

	return (
		<Modal
			open={open}
			modalHandler={modalHandler}
			containerClass="min-w-[500px]"
			title={<p className="text-lg">Unload Rota?</p>}
		>
			<div className="space-y-10">
				<div className="flex flex-col">
					<span className="">From</span>
					<DatePicker
						className="w-2/3"
						disabled={!selectedDate}
						value={moment(selectedDate, DATE_FORMATS.DD_MM_YYYY)}
						format={DATE_FORMATS.DD_MM_YYYY}
						placeholder={DATE_FORMATS.DD_MM_YYYY}
						onChange={value => {
							if (value) setSelectedDate(value)
						}}
					/>
				</div>
				<div className="flex justify-end space-x-2">
					<AntdButton onClick={() => modalHandler(false)}>Cancel</AntdButton>
					<AntdButton
						disabled={confirmationAction}
						onClick={onClick}
						loading={loader}
						className="capitalize text-black"
					>
						Unload
					</AntdButton>
				</div>
			</div>
		</Modal>
	)
}

export default UnloadRotaModal
